import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { TSimulationMenuOptions } from '@/store/types';
import { useContext } from 'react';
import { ProductionOrders } from './ProductionOrders';
import { Products } from './Products';
import { ScenarioSettings } from './ScenarioSettings';
import { Operations } from './Operations';
import { Shifts } from './Shifts';
import { Resources } from './Resources';
import { Shutdowns } from '@/pages/Simulation/TableView/Shutdowns';
import { Personnel } from '@/pages/Simulation/TableView/Personnel';

/**
 * ShowTable Function
 * Returns the appropriate table component based on the selected simulation tab option
 *
 * @param {TSimulationMenuOptions} selectedOption - The selected simulation tab option
 */
const ShowTable = (selectedOption: TSimulationMenuOptions) => {
  switch (selectedOption) {
    case 'scenario_settings':
      return <ScenarioSettings />;
    case 'products':
      return <Products />;
    case 'production_orders':
      return <ProductionOrders />;
    case 'operations':
      return <Operations />;
    case 'shifts':
      return <Shifts />;
    case 'resources':
      return <Resources />;
    case 'shutdowns':
      return <Shutdowns />;
    case 'personnel':
      return <Personnel />;
    default:
      return <ProductionOrders />;
  }
};

/**
 * TableView Component
 * Renders the appropriate table component based on the simulation tab selected in the simulation parameters
 * Uses the simulation parameters from the SimulationCtx context
 */
const TableView = () => {
  const { simulationParams } = useContext(SimulationCtx);

  return (
    <div
      style={{
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      {ShowTable(simulationParams.simulationOptionSelected)}
    </div>
  );
};

export default TableView;
