import { AppDispatch, RootState } from '@/store';
import { changeExpanded } from '@/store/storeSlice';
import {
  ResouceBuilding,
  ResourceClassTree,
  ResourceGrade,
  ResourceLifecycle,
  ResourceSubClassTree,
  ResourceTree,
  ShiftCalendarTree,
  SiteTree,
  TreeItem,
  TSimulationMenuOptions,
  WipItemParams,
  WipTree,
  WipTreeElement,
} from '@/store/types';
import {
  Button,
  Input,
  Label,
  Table,
  Tooltip,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { ReactNode, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from '../ContextMenu';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { unixTimestampToDate } from '@/utils/helpers';
import { produce } from 'immer';
import { mapStatusToFullString } from '@/pages/Simulation/TableView/Resources/utils';
import ScrapReworkPercentage from '@/components/ScrapReworkPercentage/ScrapReworkPercentage';

/**
 * Resources Component
 * Renders a table of resources with various functionalities such as filtering, expanding rows, and context menu actions
 * Manages the state for selected row, anchor element, and filter query
 */
const Resources = () => {
  const { setSimulationParams } = useContext(SimulationCtx);
  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  interface ProductTreeRender extends WipItemParams {
    RESOURCE_NAME: TreeItem;
    SITE_ID: TreeItem;
    SITE: TreeItem;
    RESOURCE_ID: TreeItem;
    RESOURCE_LIFECYCLE: TreeItem;
    BUILDING_ID: TreeItem;
    BUILDING: TreeItem;
    RESOURCE_AVAILABILITY_ILLNESS: TreeItem;
    RESOURCE_AVAILABILITY_TECH: TreeItem;
    RESOURCE_AVAILABILITY_VACATION: TreeItem;
    RESOURCE_GRADE_ID: TreeItem;
    RESOURCE_GRADE: TreeItem;
    RESOURCE_MAX_CAPACITY: TreeItem;
    RESOURCE_MTTR_ILLNESS: TreeItem;
    RESOURCE_MTTR_TECH: TreeItem;
    RESOURCE_MTTR_VACATION: TreeItem;
    RESOURCE_SHIFT_CALENDAR_ID: TreeItem;
    RESOURCE_SHIFT_CALENDAR: TreeItem;
    GSR: TreeItem;
    RESOURCE_MAX_WORKER_CAPACITY: TreeItem;
  }

  type ProductTreeRenderGeneric = {
    [K in keyof ProductTreeRender]: string | number | ReactNode;
  };

  type TProductStructure = {
    id: string;
    data: ProductTreeRenderGeneric;
    children: TProductStructure[];
    isClickable?: boolean;
    isExpanded?: boolean;
    isHidden?: boolean;
    isDisabled?: boolean;
    isSelectable?: boolean;
    area: keyof WipTree;
  };

  function findAndPushItem(
    obj: TProductStructure[],
    idToFind: string,
    newItem: TProductStructure,
  ): boolean {
    for (const item of obj) {
      if (item.id === idToFind) {
        if (!item.children) {
          item.children = []; // Ensure children array exists before pushing
        }
        item.children.push(newItem);

        item.children.sort((a, b) => {
          const stepA = (a.data.RESOURCE_NAME as string) || ''; // Use 0 as a default value if STEP.value is null or undefined
          const stepB = (b.data.RESOURCE_NAME as string) || ''; // Use 0 as a default value if STEP.value is null or undefined

          return stepA.localeCompare(stepB);
        });
        return true; // Return true to stop further recursion once the item is found
      } else if (item.children) {
        if (findAndPushItem(item.children, idToFind, newItem)) {
          return true; // If the item is found in the child, stop further recursion
        }
      }
    }
    return false; // Return false if the item is not found in the current objects or its children
  }

  type TResourceCount = {
    item: ResourceLifecycle;
    is_open: boolean;
  };

  const handleResources = (
    resource_lifecycle_id: string,
    resource_lifecycle: ResourceLifecycle[],
  ) => {
    const c = resource_lifecycle.map((item: ResourceLifecycle, idx: number) => {
      const resourceLifecycle =
        selectedScenario?.data.resource_lifecycle.byId[
          item.RESOURCE_LIFECYCLE_ID.value
        ];

      const isLastItem = idx === resource_lifecycle.length - 1;
      const borderBottomStyle = isLastItem
        ? {}
        : { borderBottom: 'solid 1px #dadada' };

      if (!resourceLifecycle) return;
      return (
        <div
          key={idx}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            padding: 5,
            gap: 20,
            ...borderBottomStyle,
          }}
        >
          <div>
            {unixTimestampToDate(
              selectedScenario?.data.resource_lifecycle.byId[
                resourceLifecycle.RESOURCE_LIFECYCLE_ID.value
              ].RESOURCE_LIFECYCLE_STATUS_START_TIMESTAMP.value,
              'dd.mm.yyyy',
            )}
          </div>
          <div>
            {unixTimestampToDate(
              selectedScenario?.data.resource_lifecycle.byId[
                resourceLifecycle.RESOURCE_LIFECYCLE_ID.value
              ].RESOURCE_LIFECYCLE_STATUS_END_TIMESTAMP.value,
              'dd.mm.yyyy',
            )}
          </div>
          <div>
            <Label labelDisplayStyle="underline">
              {mapStatusToFullString(
                resourceLifecycle.RESOURCE_LIFECYCLE_STATUS.value,
              )}
            </Label>
          </div>
          <div>
            <Label labelDisplayStyle="underline">
              {resourceLifecycle.RESOURCE_LIFECYCLE_STATUS_DISTANCE.value}
            </Label>
          </div>
        </div>
      );
    });

    const handleCustomDrawer = (
      event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>,
      formItem: string,
      formArea: TSimulationMenuOptions,
    ) => {
      event.stopPropagation();

      setSimulationParams(
        produce((draft) => {
          draft.formItem = formItem;
          draft.formArea = formArea;
          draft.drawerOpen = true;
        }),
      );
    };

    return (
      <>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              padding: 5,
              gap: 20,
              borderBottom: 'solid 1px #dadada',
              fontSize: 11,
              fontWeight: 'bold',
            }}
          >
            <div>Start</div>
            <div>End</div>
            <div>Status</div>
            <div>Distance</div>
          </div>
          {c}
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              type="secondary"
              width={'100%'}
              onClick={(event) =>
                handleCustomDrawer(
                  event,
                  resource_lifecycle_id,
                  'resource_lifecycle',
                )
              }
            >
              Manage lifecycle
            </Button>
          </div>
        </div>
      </>
    );
  };

  /**
   * ResourceLifecycleCount Component
   * Renders the count of resource lifecycle entries with a tooltip displaying the lifecycle details
   * @param {TResourceCount} props - The properties for the resource lifecycle count
   */
  const ResourceLifecycleCount = ({ item, is_open }: TResourceCount) => {
    if (!is_open) return '';
    const filteredObjects: ResourceLifecycle[] = selectedScenario
      ? selectedScenario.data.resource_lifecycle.allIds
          .map((id) => selectedScenario?.data.resource_lifecycle.byId[id])
          .filter(
            (obj) =>
              obj.RESOURCE_LIFECYCLE_ID.value ===
              item.RESOURCE_LIFECYCLE_ID.value,
          )
          .sort(
            (a, b) =>
              parseInt(a.RESOURCE_LIFECYCLE_STATUS_START_TIMESTAMP.value) -
              parseInt(b.RESOURCE_LIFECYCLE_STATUS_START_TIMESTAMP.value),
          )
      : [];

    return (
      <>
        <Tooltip
          key={'Tooltip_Resources_' + item.RESOURCE_LIFECYCLE_ID}
          color="light"
          maxWidth={500}
          title={
            <div>
              {handleResources(
                item.RESOURCE_LIFECYCLE_ID.value,
                filteredObjects,
              )}
            </div>
          }
          disableInteractive={false}
        >
          <div style={{ display: 'inline-block' }}>
            <Button type="headless" allCaps={false}>
              {filteredObjects?.length > 0 ? 'YES' : ''}
            </Button>
          </div>
        </Tooltip>
      </>
    );
  };

  /**
   * Generates the tree structure for resource data
   * Returns an array of product structure objects with nested children
   * SITE > RESOURCE CLASS > RESOURCE SUBCLASS > RESOURCE
   * @param {WipTreeElement<SiteTree>} sitesObject - The sites tree object
   * @param {WipTreeElement<ResourceClassTree>} resourcesClassesObject - The resource classes tree object
   * @param {WipTreeElement<ResourceSubClassTree>} resourcesSubClassesObject - The resource sub-classes tree object
   * @param {WipTreeElement<ResourceTree>} resourcesObject - The resources tree object
   * @param {WipTreeElement<SiteTree>} resourceSites - The resource sites tree object
   * @param {WipTreeElement<ResouceBuilding>} resourceBuildingsObject - The resource buildings tree object
   * @param {WipTreeElement<ResourceGrade>} resourceGradesObject - The resource grades tree object
   * @param {WipTreeElement<ShiftCalendarTree>} resourceShiftCalendarObject - The resource shift calendar tree object
   * @param {WipTreeElement<ResourceLifecycle>} resourceLifecycleObject - The resource lifecycle tree object
   * @returns {TProductStructure[]} - The array of product structure objects
   */
  const generateResourceTree = (
    sitesObject: WipTreeElement<SiteTree>,
    resourcesClassesObject: WipTreeElement<ResourceClassTree>,
    resourcesSubClassesObject: WipTreeElement<ResourceSubClassTree>,
    resourcesObject: WipTreeElement<ResourceTree>,
    resourceSites: WipTreeElement<SiteTree>,
    resourceBuildingsObject: WipTreeElement<ResouceBuilding>,
    resourceGradesObject: WipTreeElement<ResourceGrade>,
    resourceShiftCalendarObject: WipTreeElement<ShiftCalendarTree>,
    resourceLifecycleObject: WipTreeElement<ResourceLifecycle>,
  ): TProductStructure[] => {
    const objectWithChildren: TProductStructure[] = [];
    const itemMap: { [itemId: string]: TProductStructure } = {};
    const siteIds: string[] = [];
    console.log(resourceShiftCalendarObject);
    sitesObject.allIds.forEach((itemId: string) => {
      const item = sitesObject.byId[itemId];
      const newItem: TProductStructure = {
        id: item.SITE_ID.originalValue,
        area: 'sites',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.SITE_ID.originalValue,
          title: item.SITE.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.SITE.value,
          RESOURCE_LIFECYCLE: '',
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          RESOURCE_MAX_WORKER_CAPACITY: '',
          GSR: '',
          children: [],
        },
        children: [],
      };

      objectWithChildren.push(newItem);
      siteIds.push(item.SITE_ID.originalValue);
      itemMap[itemId] = newItem;
    });

    resourcesClassesObject.allIds.forEach((itemId: string) => {
      const item = resourcesClassesObject.byId[itemId];

      const newItem: TProductStructure = {
        id: item.RESOURCE_CLASS_ID.originalValue,
        area: 'resource_classes',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_CLASS_ID.originalValue,
          title: item.RESOURCE_CLASS.originalValue,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_CLASS.originalValue,
          RESOURCE_LIFECYCLE: '',
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          RESOURCE_MAX_WORKER_CAPACITY: '',
          GSR: '',
          children: [],
        },

        children: [],
      };
      siteIds.forEach((siteId: string) => {
        findAndPushItem(objectWithChildren, siteId, newItem);
        itemMap[itemId] = newItem;
      });
    });

    resourcesSubClassesObject.allIds.forEach((itemId: string) => {
      const item = resourcesSubClassesObject.byId[itemId];
      const classId = item.RESOURCE_CLASS_ID.value;

      const newItem: TProductStructure = {
        id: item.RESOURCE_SUBCLASS_ID.value,
        area: 'resource_sub_classes',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_SUBCLASS_ID.value,
          title: item.RESOURCE_SUBCLASS.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_SUBCLASS.value,
          RESOURCE_LIFECYCLE: '',
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          RESOURCE_MAX_WORKER_CAPACITY: '',
          GSR: '',
          children: [],
        },

        children: [],
      };
      findAndPushItem(objectWithChildren, classId, newItem);
      itemMap[itemId] = newItem;
    });

    resourcesObject.allIds.forEach((itemId: string) => {
      const item = resourcesObject.byId[itemId];
      const subclassID = item.RESOURCE_SUBCLASS_ID.value;
      const resourceLifecycleId = resourceLifecycleObject.allIds.find(
        (lifecycleId: string) => {
          const lifecycle = resourceLifecycleObject.byId[lifecycleId];
          return lifecycle.RESOURCE_ID.value === item.RESOURCE_ID.value;
        },
      );
      const newItem: TProductStructure = {
        id: item.RESOURCE_ID.originalValue,
        area: 'resources',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_ID.originalValue,
          title: item.RESOURCE_NAME.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_NAME.value,
          RESOURCE_LIFECYCLE: selectedScenario && resourceLifecycleId && (
            <ResourceLifecycleCount
              is_open={true}
              item={resourceLifecycleObject.byId[resourceLifecycleId]}
            />
          ),
          SITE_ID: item.SITE_ID.value,
          SITE:
            resourceSites.byId[item.SITE_ID.value].SITE_ABBREVIATION.value ??
            'N/A',
          RESOURCE_ID: item.RESOURCE_ID.value,
          BUILDING_ID: item.BUILDING_ID.value,
          RESOURCE_AVAILABILITY_ILLNESS:
            item.RESOURCE_AVAILABILITY_ILLNESS.value,
          RESOURCE_AVAILABILITY_TECH: item.RESOURCE_AVAILABILITY_TECH.value,
          RESOURCE_AVAILABILITY_VACATION:
            item.RESOURCE_AVAILABILITY_VACATION.value,
          RESOURCE_GRADE_ID: item.RESOURCE_GRADE_ID.value,
          RESOURCE_MAX_CAPACITY: item.RESOURCE_MAX_CAPACITY.value,
          RESOURCE_MTTR_ILLNESS: item.RESOURCE_MTTR_ILLNESS.value,
          RESOURCE_MTTR_TECH: item.RESOURCE_MTTR_TECH.value,
          RESOURCE_MTTR_VACATION: item.RESOURCE_MTTR_VACATION.value,
          RESOURCE_SHIFT_CALENDAR_ID: item.RESOURCE_SHIFT_CALENDAR_ID.value,
          BUILDING:
            resourceBuildingsObject.byId[item.BUILDING_ID.value].BUILDING
              .value ?? 'N/A',
          RESOURCE_GRADE:
            resourceGradesObject.byId[item.RESOURCE_GRADE_ID.value]
              .RESOURCE_GRADE.value ?? 'N/A',
          RESOURCE_SHIFT_CALENDAR: item.RESOURCE_SHIFT_CALENDAR.value,
          RESOURCE_MAX_WORKER_CAPACITY: item.RESOURCE_MAX_WORKER_CAPACITY.value,
          GSR: selectedScenario && (
            <ScrapReworkPercentage
              is_open={true}
              itemId={item.RESOURCE_ID.originalValue}
              itemScrap={item.RESOURCE_SCRAP.value}
              itemGood={item.RESOURCE_GOOD.value}
              itemRework={item.RESOURCE_REWORK.value}
            />
          ),
          children: [],
        },

        children: [],
      };

      if (!newItem.data.is_deleted) {
        findAndPushItem(objectWithChildren, subclassID, newItem);
        itemMap[itemId] = newItem;
      }
    });

    return objectWithChildren;
  };

  const [filterQuery, setFilterQuery] = useState<string>('');
  if (!selectedScenario) return <>No Scenario</>;

  const objectWithChildren = generateResourceTree(
    selectedScenario.data.sites,
    selectedScenario.data.resource_classes,
    selectedScenario.data.resource_sub_classes,
    selectedScenario.data.resources,
    selectedScenario.data.sites,
    selectedScenario.data.resource_buildings,
    selectedScenario.data.resource_grades,
    selectedScenario.data.shift_calendar,
    selectedScenario.data.resource_lifecycle,
  );

  const TableHeaders: TableHeaderType[] = [
    {
      title: 'Resource',
      align: 'left',
      columnKey: 'RESOURCE_NAME',
      columnWidth: '400px',
    },
    {
      title: 'Resource Lifecycle',
      align: 'center',
      columnKey: 'RESOURCE_LIFECYCLE',
    },
    {
      title: 'Shift',
      align: 'center',
      columnKey: 'RESOURCE_SHIFT_CALENDAR',
    },
    {
      title: 'Building',
      align: 'center',
      columnKey: 'BUILDING',
    },
    {
      title: 'Grade',
      align: 'center',
      columnKey: 'RESOURCE_GRADE',
    },
    {
      title: 'Max Capacity',
      align: 'center',
      columnKey: 'RESOURCE_MAX_CAPACITY',
    },
    {
      title: 'Availability Tech',
      align: 'center',
      columnKey: 'RESOURCE_AVAILABILITY_TECH',
    },
    {
      title: 'Max Worker Cap',
      align: 'center',
      columnKey: 'RESOURCE_MAX_WORKER_CAPACITY',
    },
    {
      title: 'Scrap Rework',
      align: 'center',
      columnKey: 'GSR',
    },
  ];

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(e.target.value);
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;

    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'resources') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;

    if (area !== 'resources') return;
    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input id="Filter" onChange={handleInput} placeholder="Filter" />
      </div>

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        pointerCursor={true}
        typographyTag="textsmall"
        onRowClick={(value) => handleClick(value)}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />
    </>
  );
};

export default Resources;
