import { useState, useEffect } from 'react';
import { Method } from 'axios';
import ApiCall from '@/services/ApiConnector/ApiConnector';

/**
 * useOAuth Hook
 * Manages the state and operation for fetching the OAuth access token.
 * OAuth token is used to access the https://pd.gallery.siemens-energy.com/ endpoint
 * Uses the provided base URL, client ID, and client secret for the API request.
 * @param {string} baseUrl - The base URL for the API
 * @param {string} clientId - The client ID for OAuth
 * @param {string} clientSecret - The client secret for OAuth
 */

const useOAuth = (baseUrl: string, clientId: string, clientSecret: string) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAccessToken = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await ApiCall(
          'POST' as Method,
          '/webapi/oauth2/token',
          { baseUrl },
          {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`
        );

        setAccessToken(response.data.access_token);

      } catch (err) {
        setError('Failed to fetch access token');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessToken();
  }, [baseUrl, clientId, clientSecret]);

  return { accessToken, error, loading };
};

export default useOAuth;
