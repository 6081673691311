import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import {
  Button,
  Label,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  DroppableProps,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FormOperationsSteps.module.scss';
import { produce } from 'immer';
import { updateProcessSteps } from '@/store/storeSlice';
import { TProcessStepOrder } from '@/store/types';

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

/**
 * FormOperationsSteps Component
 * Manages the form for reordering operation steps within a work plan
 * Handles form submission, drag-and-drop reordering, and closing the form drawer
 */
const FormOperationsSteps = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  useEffect(() => {
    const OperationsArr: TProcessStepOrder[] =
      !simulationParams.formItem ||
      !selectedScenario?.data.work_plans.byId[simulationParams.formItem]
        ?.children
        ? []
        : selectedScenario.data.work_plans.byId[
            simulationParams.formItem
          ].children
            .map((item) => ({
              id: item,
              step: parseInt(
                selectedScenario?.data.operations.byId[item].STEP.value,
              ),
              operation:
                selectedScenario?.data.operations.byId[item].OPERATION.value,
            }))
            .sort((a, b) => a.step - b.step);
    setItems(OperationsArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationParams.formItem]);

  const [items, setItems] = useState<TProcessStepOrder[]>([]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      updateProcessSteps({
        id: simulationParams.formItem,

        content: items,
      }),
    );

    handleCloseDrawer();
  };

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setItems([]);
  };

  const onDragEnd = (result: DropResult) => {
    /*   alert('test'); */
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setItems(newItems);
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'white' : 'white',
    width: '100%',
    margin: 'auto',
  });
  //'#E9DDF0'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getItemStyle(isDragging: boolean, style: any) {
    // Define the base styles for the draggable items
    const baseStyles = {
      userSelect: 'none',

      background: isDragging ? '#fafafa' : 'white',
      color: isDragging ? 'black' : 'black',
      height: 40,
      padding: 10,
      marginBottom: isDragging ? -50 : 0,
      fontSize: 14,
      position: isDragging ? 'static' : '',
      borderBottom: ' solid #dadada 1px',
    };

    // Check if the draggable item is being transformed
    if (style.transform) {
      const axisLockY = `translate(0px, ${style.transform
        .split(',')[1]
        .replaceAll(')', '')})`;
      return {
        ...style,
        transform: axisLockY,

        ...baseStyles,
      };
    }

    // Return the combined styles without axis locking transformation
    return {
      ...style,
      ...baseStyles,
    };
  }

  if (!simulationParams.formItem) return 'Not found';
  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <div style={{ padding: 10, marginBottom: 10, marginTop: 10 }}>
          <Label tag="textsmall_strong">Workplan</Label>
          <Typography tag="textsmall">
            {
              selectedScenario?.data.work_plans.byId[simulationParams.formItem]
                .WORK_PLAN.value
            }
          </Typography>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '20px 1fr',
            gap: 20,
            padding: 10,
            borderBottom: ' solid #dadada 1px',
          }}
        >
          <Typography tag="textextrasmall_strong">Step</Typography>

          <Typography tag="textextrasmall_strong">Process</Typography>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable
            droppableId="droppable"
            type="group"
            direction="vertical"
          >
            {(provided: DroppableProvided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        key={`li_${index}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        /* style={getStyle2({
                        snapshot.isDragging,
                        provided.draggableProps.style,
                    })} */
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                        className={styles.Line}
                      >
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '20px 1fr',
                            gap: 20,
                          }}
                        >
                          <div className={styles.LineNumber}>{index + 1}</div>
                          <div>{item.operation}</div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        <div style={{ padding: 10, marginTop: 20 }}>
          <Typography tag="textsmall" color="#CCCCCC">
            Drag the operations to reorder them
          </Typography>
        </div>

        {/*  {<pre>{JSON.stringify(items, null, 2)}</pre>} */}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormOperationsSteps;
