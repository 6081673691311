/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from '@/store';
import {
  ShutdownTree,
  SiteTree,
  TreeItem,
  TSimulationMenuOptions,
  WipItemParams,
  WipTree,
  WipTreeElement,
} from '@/store/types';
import { unixTimestampToDate } from '@/utils/helpers';
import { Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from '../ContextMenu';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { changeExpanded } from '@/store/storeSlice';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';

interface ProductTreeRender extends WipItemParams {
  REDUCED_ON: TreeItem;
  REDUCED_TIME: TreeItem;
  SHUTDOWN_DATE: TreeItem;
  SHUTDOWN_TITLE: TreeItem;
  SHUTDOWN_TIMESTAMP: number;
  SHUTDOWN_TYPE: TreeItem;
  WORKING_OR_NOT: TreeItem;
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};

type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: TSimulationMenuOptions;
};

const TableHeaders: TableHeaderType[] = [
  {
    title: 'Shutdown',
    align: 'left',
    columnKey: 'SHUTDOWN_TITLE',
    columnWidth: '400px',
  },
  {
    title: 'Date',
    align: 'center',
    columnKey: 'SHUTDOWN_DATE',
  },
  {
    title: 'Type',
    align: 'center',
    columnKey: 'SHUTDOWN_TYPE',
  },
  {
    title: 'Working day',
    align: 'center',
    columnKey: 'WORKING_OR_NOT',
  },
  { title: 'Reduced Time', align: 'center', columnKey: 'REDUCED_ON' },
];

/**
 * Generates an object with children from sites and shutdowns tree objects
 * Returns an array of product structure objects
 * SITE > SHUTDOWN YEAR > SHUTDOWN
 * @param {WipTreeElement<SiteTree>} sitesObject - The sites tree object
 * @param {WipTreeElement<ShutdownTree>} shutdownsObject - The shutdowns tree object
 */
const generateObjectWithChildren = (
  sitesObject: WipTreeElement<SiteTree>,
  shutdownsObject: WipTreeElement<ShutdownTree>,
): TProductStructure[] => {
  const objectWithChildren: any[] = [];
  const itemMap: { [itemId: string]: TProductStructure } = {};

  sitesObject.allIds.forEach((itemId: string) => {
    const item = sitesObject.byId[itemId];

    const newItem: TProductStructure = {
      id: item.SITE_ID.originalValue,
      area: 'sites',
      isExpanded: item.is_open,
      isDisabled: item.is_disabled,
      data: {
        key: item.SITE_ID.originalValue,
        title: item.SITE.value,
        is_wip: item.is_wip,
        is_deleted: item.is_deleted,
        is_disabled: item.is_disabled,
        is_clicked: item.is_clicked,
        is_selectable: item.is_selectable,
        is_selected: item.is_selected,
        is_hidden: item.is_hidden,
        is_match: item.is_match,
        is_open: item.is_open,
        parent: '',
        SHUTDOWN_TITLE: item.SITE.value,

        REDUCED_ON: '',
        REDUCED_TIME: '',
        SHUTDOWN_DATE: '',
        SHUTDOWN_TYPE: '',
        SHUTDOWN_TIMESTAMP: '',
        WORKING_OR_NOT: '',

        children: [],
      },
      children: [],
    };

    objectWithChildren.push(newItem);
    itemMap[itemId] = newItem;
  });

  shutdownsObject.allIds.forEach((itemId: string) => {
    const item = shutdownsObject.byId[itemId];
    const siteId = item.SITE_ID.value;

    if (!sitesObject.byId[siteId]) return;

    const date = new Date(item.SHUTDOWN_DATE.value * 1000);
    const year = date.getFullYear();

    const newItem: TProductStructure = {
      id: year.toString(),
      area: 'shutdown_years',
      isExpanded: item.is_open,
      isDisabled: item.is_disabled,
      data: {
        key: item.SITE_ID.originalValue,
        title: year,
        is_wip: item.is_wip,
        is_deleted: item.is_deleted,
        is_disabled: item.is_disabled,
        is_clicked: item.is_clicked,
        is_selectable: item.is_selectable,
        is_selected: item.is_selected,
        is_hidden: item.is_hidden,
        is_match: item.is_match,
        is_open: item.is_open,
        parent: '',
        SHUTDOWN_TITLE: year,

        REDUCED_ON: '',
        REDUCED_TIME: '',
        SHUTDOWN_DATE: '',
        SHUTDOWN_TYPE: '',
        SHUTDOWN_TIMESTAMP: '',
        WORKING_OR_NOT: '',

        children: [],
      },
      children: [],
    };

    if (!newItem.data.is_deleted && !itemMap[year.toString()]) {
      objectWithChildren
        .find((obj) => obj.id === siteId)
        .children.push(newItem);

      objectWithChildren
        .find((obj) => obj.id === siteId)
        .children.sort((a: TProductStructure, b: TProductStructure) => {
          const stepA = (a.data.title as number) || 0;
          const stepB = (b.data.title as number) || 0;

          return stepA - stepB;
        });

      itemMap[year.toString()] = newItem;
    }
  });

  shutdownsObject.allIds.forEach((itemId: string) => {
    const item = shutdownsObject.byId[itemId];
    const siteId = item.SITE_ID.value;

    if (!sitesObject.byId[siteId]) return;

    const date = new Date(item.SHUTDOWN_DATE.value * 1000);
    const year = date.getFullYear();

    const newItem: TProductStructure = {
      id: item.SHUTDOWN_ID.originalValue,
      area: 'shutdowns',
      isExpanded: item.is_open,
      isDisabled: item.is_disabled,
      data: {
        key: item.SHUTDOWN_ID.originalValue,
        title: item.SHUTDOWN_TITLE.value,
        is_wip: item.is_wip,
        is_deleted: item.is_deleted,
        is_disabled: item.is_disabled,
        is_clicked: item.is_clicked,
        is_selectable: item.is_selectable,
        is_selected: item.is_selected,
        is_hidden: item.is_hidden,
        is_match: item.is_match,
        is_open: item.is_open,
        parent: '',
        SHUTDOWN_TITLE: item.SHUTDOWN_TITLE.value,

        REDUCED_ON:
          item.REDUCED_ON.value === 1
            ? item.REDUCED_TIME.value +
              ' hour' +
              (item.REDUCED_TIME.value > 1 ? 's' : '')
            : '-',
        REDUCED_TIME: item.REDUCED_TIME.value === 1 ? 'Yes' : 'No',
        SHUTDOWN_DATE: unixTimestampToDate(
          item.SHUTDOWN_DATE.value,
          'dd.mm.yyyy',
        ),
        SHUTDOWN_TIMESTAMP: item.SHUTDOWN_DATE.value,
        SHUTDOWN_TYPE: item.SHUTDOWN_TYPE.value,
        WORKING_OR_NOT: item.WORKING_OR_NOT.value === 1 ? 'Yes' : 'No',
        children: [],
      },

      children: [],
    };

    if (!newItem.data.is_deleted) {
      findAndPushItem(objectWithChildren, year.toString(), newItem);
      itemMap[itemId] = newItem;
    }
  });

  return objectWithChildren;
};

/**
 * Finds an item by id in a tree structure and pushes a new item into its children
 * Sorts the children by the SHUTDOWN_TIMESTAMP property
 * @param {TProductStructure[]} obj - The tree structure to search
 * @param {string} idToFind - The id of the item to find
 * @param {TProductStructure} newItem - The new item to push into the children
 * @returns {boolean} - True if the item is found and pushed, false otherwise
 */
function findAndPushItem(
  obj: TProductStructure[],
  idToFind: string,
  newItem: TProductStructure,
): boolean {
  for (const item of obj) {
    if (item.id === idToFind) {
      if (!item.children) {
        item.children = []; // Ensure children array exists before pushing
      }
      item.children.push(newItem);
      item.children.sort((a, b) => {
        const stepA = (a.data.SHUTDOWN_TIMESTAMP as number) || 0; // Use 0 as a default value if STEP.value is null or undefined
        const stepB = (b.data.SHUTDOWN_TIMESTAMP as number) || 0; // Use 0 as a default value if STEP.value is null or undefined

        return stepA - stepB;
      });

      return true; // Return true to stop further recursion once the item is found
    } else if (item.children) {
      if (findAndPushItem(item.children, idToFind, newItem)) {
        return true; // If the item is found in the child, stop further recursion
      }
    }
  }
  return false; // Return false if the item is not found in the current objects or its children
}
/**
 * Shutdowns Component
 * Renders a table of shutdowns with various functionalities such as filtering, expanding rows, and context menu actions
 * Manages the state for selected row, anchor element, and expanded years
 */
const Shutdowns = () => {
  const { setSimulationParams } = useContext(SimulationCtx);
  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const [expandedYears, setExpandedYears] = useState<{
    [key: string]: boolean;
  }>({});

  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  useEffect(() => {
    if (selectedScenario) {
      const years = new Set<string>();
      selectedScenario.data.shutdowns.allIds.forEach((itemId: string) => {
        const item = selectedScenario.data.shutdowns.byId[itemId];
        const date = new Date(item.SHUTDOWN_DATE.value * 1000);
        const year = date.getFullYear();
        years.add(year.toString());
      });

      const initialExpandedYears: { [key: string]: boolean } = {};
      years.forEach((year) => {
        initialExpandedYears[year] = false;
      });
      setExpandedYears(initialExpandedYears);
    }
  }, [selectedScenario]);

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'shutdowns') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;
    if (value.area === 'shutdown_years') {
      setExpandedYears((prev) => ({
        ...prev,
        [value.id]: !prev[value.id],
      }));
    } else {
      dispatch(
        changeExpanded({
          id: value.id,
          isExpanded: value.isExpanded ?? false,
          area: value.area as keyof WipTree,
        }),
      );
    }
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;
    if (area !== 'shutdowns') return;
    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  /**
   * memoizedObj Constant
   * Memoizes the object with children to optimize rendering
   * Applies the expanded state to the shutdown_years items
   *
   * @returns {TProductStructure[]} - The memoized array of product structure objects
   */
  const memoizedObj = React.useMemo(() => {
    if (!selectedScenario) return [];
    const obj = generateObjectWithChildren(
      selectedScenario.data.sites,
      selectedScenario.data.shutdowns,
    );

    // Apply the expanded state to the shutdown_years items
    obj.forEach((item) => {
      item.children.forEach((child) => {
        if (
          child.area === 'shutdown_years' &&
          expandedYears[child.id] !== undefined
        ) {
          child.isExpanded = expandedYears[child.id];
        }
      });
    });
    return obj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScenario, expandedYears]);

  /**
   * memoizedTable Constant
   * Memoizes the table component to optimize rendering
   *
   * @returns {JSX.Element} - The memoized table component
   */
  const memoizedTable = React.useMemo(() => {
    return (
      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        rows={memoizedObj}
        typographyTag="textsmall"
        pointerCursor={true}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowClick={(value) => handleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedObj]);

  if (!selectedScenario) return <>No Scenario</>;

  return (
    <>
      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />

      {memoizedTable}
    </>
  );
};

export default Shutdowns;
