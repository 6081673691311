import { createContext } from 'react';
export type MenuParamsType = {
  height: string;
  width: string;
  defaultWidth: string;
  collapsedWidth: string;
  top: string;
  isCollapsed: boolean;
};
export type ContentParamsType = {
  height: string;
  width: string;
  top: string;
  left: string;
};
export type HeaderParamsType = {
  height: string;
  width: string;
  top: string;
};
export type FooterParamsType = {
  height: string;
  width: string;
  bottom: string;
};

const defaultMenuParams: MenuParamsType = {
  height: '100vh',
  width: '0px',
  defaultWidth: '0px',
  collapsedWidth: '100px',
  top: '0px',
  isCollapsed: false,
};

const defaultContentParams: ContentParamsType = {
  height: '100vh',
  width: '100%',
  top: '0px',
  left: '0px',
};
const defaultContentMenuParams: ContentParamsType = {
  height: '0px',
  width: '100%',
  top: '0px',
  left: '0px',
};
const defaultHeaderParams: HeaderParamsType = {
  height: '0px',
  width: '100%',
  top: '0px',
};
const defaultFooterParams: FooterParamsType = {
  height: '0px',
  width: '100%',
  bottom: '0px',
};

/**
 * LayoutCtx Context
 * Provides default layout parameters and functions to update them.
 * Includes parameters for menu, content, header, and footer.
 */
export const LayoutCtx = createContext<{
  menuParams: MenuParamsType;
  contentParams: ContentParamsType;
  contentMenuParams: ContentParamsType;
  headerParams: HeaderParamsType;
  footerParams: FooterParamsType;
  setMenuParams: React.Dispatch<React.SetStateAction<MenuParamsType>>;
  setContentParams: React.Dispatch<React.SetStateAction<ContentParamsType>>;
  setContentMenuParams: React.Dispatch<React.SetStateAction<ContentParamsType>>;
  setHeaderParams: React.Dispatch<React.SetStateAction<HeaderParamsType>>;
  setFooterParams: React.Dispatch<React.SetStateAction<FooterParamsType>>;
}>({
  menuParams: defaultMenuParams,
  contentParams: defaultContentParams,
  contentMenuParams: defaultContentMenuParams,
  headerParams: defaultHeaderParams,
  footerParams: defaultFooterParams,
  setMenuParams: () => {},
  setContentParams: () => {},
  setContentMenuParams: () => {},
  setHeaderParams: () => {},
  setFooterParams: () => {},
});
