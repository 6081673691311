import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { Button } from '@data-products-and-ai/react-components';
import { useContext} from 'react';
import UploadPPRZone from '@/components/UploadPPRZone/UploadPPRZone';

/**
 * SimulationNotStarted Component
 * Renders a button to create a new simulation and the upload zone component
 * Uses the simulation parameters from the SimulationCtx context
 *
 * @returns {JSX.Element} - The rendered component
 */
const SimulationNotStarted = () => {
  const { setSimulationParams } = useContext(SimulationCtx);

  return (
    <>
      <div style={{ padding: 20 }}>
        <Button
          width="100%"
          onClick={() =>
            setSimulationParams((prevState) => ({
              ...prevState,
              simulationForm: true,
            }))
          }
        >
          Create Simulation
        </Button>
      </div>
      <div style={{ margin: 0, borderBottom: 'solid 1px #ededed' }} />
      <UploadPPRZone/>
    </>
  );
};

export default SimulationNotStarted;