import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import { update } from '@/store/storeSlice';
import { TreeItem } from '@/store/types';
import {
  Button,
  DatePicker,
  Input,
  Label,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type TFormProductionOrders = {
  SHUTDOWN_ID: TreeItem;
  SHUTDOWN_TITLE: TreeItem;
  SHUTDOWN_DATE: TreeItem<number>;
  REDUCED_ON: TreeItem<number>;
  SITE_ID: TreeItem;
  SHUTDOWN_TYPE: TreeItem;
  WORKING_OR_NOT: TreeItem<number>;
  REDUCED_TIME: TreeItem<number>;
};

/**
 * FormShutdowns Component
 * Manages the form for editing shutdown details within a simulation scenario
 * Manages form state and handles form submission
 *
 * @returns {JSX.Element} - The rendered component
 */
const FormShutdowns = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const InitialFormProductionOrders: TFormProductionOrders = {
    SHUTDOWN_ID: { value: '', originalValue: '' },
    SHUTDOWN_TITLE: { value: '', originalValue: '' },
    SHUTDOWN_DATE: { value: 0, originalValue: 0 },
    REDUCED_ON: { value: 0, originalValue: 0 },
    SITE_ID: { value: '', originalValue: '' },
    SHUTDOWN_TYPE: { value: '', originalValue: '' },
    REDUCED_TIME: { value: 0, originalValue: 0 },
    WORKING_OR_NOT: { value: 0, originalValue: 0 },
  };

  const [form, setForm] = useState(InitialFormProductionOrders);

  const handleDateChange = (
    id: keyof TFormProductionOrders,
    value: number | null,
  ) => {
    const updateForm = (id: keyof TFormProductionOrders, newValue: unknown) => {
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: newValue ?? 0,
        },
      }));
    };

    updateForm(id as keyof TFormProductionOrders, value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { id, value } = event.target as HTMLInputElement & {
      id: keyof TFormProductionOrders;
      value: string;
      type: string;
      checked: boolean;
    };
    const updateForm = (id: keyof TFormProductionOrders, newValue: unknown) => {
      let v = newValue;
      if (typeof form[id].value === 'number') {
        v = parseInt(v as string);
      }
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: v,
        },
      }));

      if (id === 'WORKING_OR_NOT' && v === 0) {
        setForm((prevForm) => ({
          ...prevForm,
          REDUCED_ON: {
            ...prevForm.REDUCED_ON,
            value: 0,
          },
          REDUCED_TIME: {
            ...prevForm.REDUCED_ON,
            value: 0,
          },
        }));
      }

      if (id === 'REDUCED_ON' && v === 0) {
        setForm((prevForm) => ({
          ...prevForm,

          REDUCED_TIME: {
            ...prevForm.REDUCED_ON,
            value: 0,
          },
        }));
      }
    };
    updateForm(id, value);
  };

  /*  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleInputChange(event, setForm, props.parameterIndex);
    },
    1000,
  ); */

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setForm(InitialFormProductionOrders);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      update({
        id: simulationParams.formItem,
        area: 'shutdowns',
        content: form,
      }),
    );

    handleCloseDrawer();
  };

  const selectedItem =
    simulationParams.formItem && selectedScenario
      ? selectedScenario.data.shutdowns.byId[simulationParams.formItem]
      : null;

  useEffect(() => {
    if (!selectedItem) return;

    const formToChange: TFormProductionOrders = {
      SHUTDOWN_ID: {
        value: selectedItem.SHUTDOWN_ID.value,
        originalValue: selectedItem.SHUTDOWN_ID.originalValue,
      },

      SHUTDOWN_TITLE: {
        value: selectedItem.SHUTDOWN_TITLE.value,
        originalValue: selectedItem.SHUTDOWN_TITLE.originalValue,
      },
      SHUTDOWN_DATE: {
        value: selectedItem.SHUTDOWN_DATE.value,
        originalValue: selectedItem.SHUTDOWN_DATE.originalValue,
      },

      REDUCED_ON: {
        value: selectedItem.REDUCED_ON.value,
        originalValue: selectedItem.REDUCED_ON.originalValue,
      },
      SITE_ID: {
        value: selectedItem.SITE_ID.value,
        originalValue: selectedItem.SITE_ID.originalValue,
      },
      SHUTDOWN_TYPE: {
        value: selectedItem.SHUTDOWN_TYPE.value,
        originalValue: selectedItem.SHUTDOWN_TYPE.originalValue,
      },
      REDUCED_TIME: {
        value: selectedItem.REDUCED_TIME.value,
        originalValue: selectedItem.REDUCED_TIME.originalValue,
      },
      WORKING_OR_NOT: {
        value: selectedItem.WORKING_OR_NOT.value,
        originalValue: selectedItem.WORKING_OR_NOT.originalValue,
      },
    };
    setForm(formToChange);
  }, [selectedItem]);

  if (!selectedItem || !selectedScenario) return;

  type TSelect = {
    id: string;
    name: string;
  };

  const sites: TSelect[] = [];
  selectedScenario.data.sites.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.sites.byId[itemId];

    const newItem = {
      id: item.SITE_ID.originalValue,
      name: item.SITE.value,
    };

    sites.push(newItem);
  });
  const workplans: TSelect[] = [];
  selectedScenario.data.work_plans.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.work_plans.byId[itemId];

    const newItem = {
      id: item.WORK_PLAN_ID.originalValue,
      name: item.WORK_PLAN.value,
    };

    workplans.push(newItem);
  });

  const products: TSelect[] = [];
  selectedScenario.data.products.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.products.byId[itemId];

    const newItem = {
      id: item.PRODUCT_ID.originalValue,
      name: item.PRODUCT.value,
    };

    products.push(newItem);
  });

  const formMargin = 30;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <Label tag="textsmall_strong">Title</Label>
        <Input
          size="small"
          id="SHUTDOWN_TITLE"
          defaultValue={form.SHUTDOWN_TITLE.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>

        <Label tag="textsmall_strong" marginTop={formMargin}>
          Type
        </Label>
        <Input
          size="small"
          id="SHUTDOWN_TYPE"
          defaultValue={form.SHUTDOWN_TYPE.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>
        {/*   <Select
          onChange={(event) => handleInputChange(event)}
          size="small"
          defaultValue={form.PRODUCT_ID.value}
          id="PRODUCT_ID"
        >
          {products.map((el: TSelect) => {
            return <SelectOption key={el.id} label={el.name} value={el.id} />;
          })}
        </Select> */}

        <div>
          <Label tag="textsmall_strong" marginTop={formMargin}>
            Shutdown Date
          </Label>
          <DatePicker
            size="small"
            id="SHUTDOWN_DATE"
            defaultValue={form.SHUTDOWN_DATE.value}
            onChange={(value) => handleDateChange('SHUTDOWN_DATE', value)}
          />
        </div>

        <Label marginTop={formMargin} tag="textsmall_strong">
          Working day
        </Label>
        <Typography tag="textsmall">
          <RadioGroup
            id="WORKING_OR_NOT"
            orientation="row"
            gap={20}
            onChange={(event) => handleInputChange(event)}
          >
            <Radio
              name="WORKING_OR_NOT"
              isChecked={form.WORKING_OR_NOT.value === 1}
              label="Yes"
              value="1"
            />

            <Radio
              name="WORKING_OR_NOT"
              isChecked={form.WORKING_OR_NOT.value === 0}
              label="No"
              value="0"
            />
          </RadioGroup>
        </Typography>

        {form.WORKING_OR_NOT.value === 1 && (
          <>
            <Label marginTop={formMargin} tag="textsmall_strong">
              Reduced
            </Label>
            <Typography tag="textsmall">
              <RadioGroup
                id="REDUCED_ON"
                orientation="row"
                gap={20}
                onChange={(event) => handleInputChange(event)}
              >
                <Radio
                  name="REDUCED_ON"
                  isChecked={form.REDUCED_ON.value === 1}
                  label="Yes"
                  value="1"
                />

                <Radio
                  name="REDUCED_ON"
                  isChecked={form.REDUCED_ON.value === 0}
                  label="No"
                  value="0"
                />
              </RadioGroup>
            </Typography>
          </>
        )}
        {form.WORKING_OR_NOT.value === 1 && form.REDUCED_ON.value === 1 && (
          <>
            <Label marginTop={formMargin} tag="textsmall_strong">
              Reduced Time (hours)
            </Label>
            <Slider
              id="REDUCED_TIME"
              defaultValue={form.REDUCED_TIME.value}
              min={1}
              max={23}
              sliderType="minimized"
              width={'420px'}
              onChange={(event) => handleInputChange(event)}
            />
          </>
        )}
        {/*  <pre>{JSON.stringify(form, null, 2)}</pre> */}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormShutdowns;
