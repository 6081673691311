import { Input } from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';

type TOperationsTimePicker = {
  id: string;
  days?: number;
  hours?: number;
  onChange?: (value: number) => void;
};

/**
 * FormResourcesTimePicker Component
 * Renders a time picker with inputs for days and hours
 * Manages the state for each time unit and calls the onChange callback with the combined value in seconds
 *
 * @param {string} id - The unique identifier for the time picker
 * @param {number} [days=0] - The initial value for days
 * @param {number} [hours=0] - The initial value for hours
 * @param {function} [onChange] - The callback function to be called with the combined time value in seconds
 * @returns {JSX.Element} - The rendered component
 */
const FormResourcesTimePicker = ({
  id,
  days = 0,
  hours = 0,
  onChange,
}: TOperationsTimePicker) => {
  const [pickerDays, setPickerDays] = useState(days);
  const [pickerHours, setPickerHours] = useState(hours);

  const handleInputChange = (inputType: string, value: number) => {
    if (!value) value = 0;
    if (inputType === 'hours') {
      setPickerHours(value);
    } else if (inputType === 'days') {
      setPickerDays(value);
    }
  };

  useEffect(() => {
    const combinedValue = pickerHours * 3600 + pickerDays * 3600 * 24;

    console.log(combinedValue);
    if (onChange) {
      onChange(combinedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerHours, pickerDays]);

  return (
    <div
      style={{ display: 'grid', gridTemplateColumns: '100px 100px', gap: 20 }}
    >
      <Input
        width={50}
        id={`${id}_days`}
        textAfter="Days"
        size="small"
        defaultValue={days.toString()}
        onChange={(event) =>
          handleInputChange('days', parseInt(event.target.value))
        }
      />
      <Input
        width={50}
        id={`${id}_hours`}
        textAfter="Hours"
        size="small"
        defaultValue={hours.toString()}
        onChange={(event) =>
          handleInputChange('hours', parseInt(event.target.value))
        }
      />
    </div>
  );
};

export default FormResourcesTimePicker;
