import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import { update } from '@/store/storeSlice';
import { TreeItem } from '@/store/types';
import { formatHoursToTime, formatSecondsToHours } from '@/utils/helpers';
import {
  Button,
  Input,
  Label,
  Select,
  SelectOption,
  Slider,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormResourcesTimePicker from './FormResourcesTimePicker';

export type TFormProductionOrders = {
  BUILDING_ID: TreeItem;
  RESOURCE_AVAILABILITY_ILLNESS: TreeItem<number>;
  RESOURCE_AVAILABILITY_TECH: TreeItem<number>;
  RESOURCE_AVAILABILITY_VACATION: TreeItem<number>;
  RESOURCE_MAX_CAPACITY: TreeItem<number>;
  RESOURCE_MTTR_ILLNESS: TreeItem<number>;
  RESOURCE_MTTR_TECH: TreeItem<number>;
  RESOURCE_MTTR_VACATION: TreeItem<number>;
  RESOURCE_SHIFT_CALENDAR_ID: TreeItem;
  RESOURCE_NAME: TreeItem;
  RESOURCE_GRADE_ID: TreeItem;
};

/**
 * FormResources Component
 * Manages the form for editing resource details within a simulation scenario
 * Handles form submission, input changes, time changes, and closing the form drawer
 *
 * @returns {JSX.Element} - The rendered component
 */
const FormResources = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const InitialFormProductionOrders: TFormProductionOrders = {
    BUILDING_ID: { value: '', originalValue: '' },
    RESOURCE_AVAILABILITY_ILLNESS: { value: 0, originalValue: 0 },
    RESOURCE_AVAILABILITY_TECH: { value: 0, originalValue: 0 },
    RESOURCE_AVAILABILITY_VACATION: { value: 0, originalValue: 0 },
    RESOURCE_MAX_CAPACITY: { value: 0, originalValue: 0 },
    RESOURCE_MTTR_ILLNESS: { value: 0, originalValue: 0 },
    RESOURCE_MTTR_TECH: { value: 0, originalValue: 0 },
    RESOURCE_MTTR_VACATION: { value: 0, originalValue: 0 },
    RESOURCE_SHIFT_CALENDAR_ID: { value: '', originalValue: '' },
    RESOURCE_NAME: { value: '', originalValue: '' },
    RESOURCE_GRADE_ID: { value: '', originalValue: '' },
  };

  const [form, setForm] = useState(InitialFormProductionOrders);

  /**
   * handleTimeChange Function
   * Updates the form state when a time value changes
   * Sets the new time value for the specified form field
   *
   * @param {keyof TFormProductionOrders} id - The form field ID
   * @param {number} value - The new time value
   */
  const handleTimeChange = (id: keyof TFormProductionOrders, value: number) => {
    console.log('time', id, value);
    setForm((prevForm) => ({
      ...prevForm,
      [id]: {
        ...prevForm[id],
        value: value,
      },
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { id, value } = event.target as HTMLInputElement & {
      id: string;
      value: string;
      type: string;
      checked: boolean;
    };
    const updateForm = (id: keyof TFormProductionOrders, newValue: unknown) => {
      let v = newValue;
      if (typeof form[id] === 'number') {
        v = parseInt(v as string);
      }
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: v,
        },
      }));
    };

    updateForm(id as keyof TFormProductionOrders, value);
  };

  /*  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleInputChange(event, setForm, props.parameterIndex);
    },
    1000,
  ); */

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setForm(InitialFormProductionOrders);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      update({
        id: simulationParams.formItem,
        area: 'resources',
        content: form,
      }),
    );

    handleCloseDrawer();
  };

  const selectedItem =
    simulationParams.formItem && selectedScenario
      ? selectedScenario.data.resources.byId[simulationParams.formItem]
      : null;

  useEffect(() => {
    if (!selectedItem) return;

    const formToChange: TFormProductionOrders = {
      BUILDING_ID: {
        value: selectedItem.BUILDING_ID.value,
        originalValue: selectedItem.BUILDING_ID.originalValue,
      },

      RESOURCE_AVAILABILITY_ILLNESS: {
        value: parseInt(selectedItem.RESOURCE_AVAILABILITY_ILLNESS.value),
        originalValue: parseInt(
          selectedItem.RESOURCE_AVAILABILITY_ILLNESS.originalValue,
        ),
      },
      RESOURCE_AVAILABILITY_TECH: {
        value: parseInt(selectedItem.RESOURCE_AVAILABILITY_TECH.value),
        originalValue: parseInt(
          selectedItem.RESOURCE_AVAILABILITY_TECH.originalValue,
        ),
      },

      RESOURCE_AVAILABILITY_VACATION: {
        value: parseInt(selectedItem.RESOURCE_AVAILABILITY_VACATION.value),
        originalValue: parseInt(
          selectedItem.RESOURCE_AVAILABILITY_VACATION.originalValue,
        ),
      },
      RESOURCE_MAX_CAPACITY: {
        value: parseInt(selectedItem.RESOURCE_MAX_CAPACITY.value),
        originalValue: parseInt(
          selectedItem.RESOURCE_MAX_CAPACITY.originalValue,
        ),
      },
      RESOURCE_MTTR_ILLNESS: {
        value: parseInt(selectedItem.RESOURCE_MTTR_ILLNESS.value),
        originalValue: parseInt(
          selectedItem.RESOURCE_MTTR_ILLNESS.originalValue,
        ),
      },
      RESOURCE_MTTR_TECH: {
        value: parseInt(selectedItem.RESOURCE_MTTR_TECH.value),
        originalValue: parseInt(selectedItem.RESOURCE_MTTR_TECH.originalValue),
      },
      RESOURCE_MTTR_VACATION: {
        value: parseInt(selectedItem.RESOURCE_MTTR_VACATION.value),
        originalValue: parseInt(
          selectedItem.RESOURCE_MTTR_VACATION.originalValue,
        ),
      },
      RESOURCE_SHIFT_CALENDAR_ID: {
        value: selectedItem.RESOURCE_SHIFT_CALENDAR_ID.value,
        originalValue: selectedItem.RESOURCE_SHIFT_CALENDAR_ID.originalValue,
      },
      RESOURCE_NAME: {
        value: selectedItem.RESOURCE_NAME.value,
        originalValue: selectedItem.RESOURCE_NAME.originalValue,
      },
      RESOURCE_GRADE_ID: {
        value: selectedItem.RESOURCE_GRADE_ID.value,
        originalValue: selectedItem.RESOURCE_GRADE_ID.originalValue,
      },
    };
    setForm(formToChange);
  }, [selectedItem]);

  if (!selectedItem || !selectedScenario) return;

  type TSelect = {
    id: string;
    name: string;
  };

  const resourceBuildings: TSelect[] = [];
  selectedScenario.data.resource_buildings.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.resource_buildings.byId[itemId];

    const newItem = {
      id: item.BUILDING_ID.value,
      name: item.BUILDING.value,
    };

    if (item.SITE_ID.value === selectedItem.SITE_ID.value)
      resourceBuildings.push(newItem);
  });
  const resourceGrades: TSelect[] = [];
  selectedScenario.data.resource_grades.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.resource_grades.byId[itemId];

    const newItem = {
      id: item.RESOURCE_GRADE_ID.value,
      name: item.RESOURCE_GRADE.value,
    };

    if (item.SITE_ID.value === selectedItem.SITE_ID.value)
      resourceGrades.push(newItem);
  });

  const shiftCalendar: TSelect[] = [];
  selectedScenario.data.shift_calendar.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.shift_calendar.byId[itemId];

    const newItem = {
      id: item.RESOURCE_SHIFT_CALENDAR_ID.value,
      name: item.RESOURCE_SHIFT_CALENDAR.value,
    };

    if (item.SITE_ID.value === selectedItem.SITE_ID.value)
      shiftCalendar.push(newItem);
  });

  const MTTR_Illness = formatHoursToTime(form.RESOURCE_MTTR_ILLNESS.value);
  const MTTR_Tech = formatHoursToTime(form.RESOURCE_MTTR_TECH.value);
  const MTTR_Vacation = formatHoursToTime(form.RESOURCE_MTTR_VACATION.value);

  const formMargin = 30;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 20,
            marginBottom: formMargin,
            marginTop: formMargin / 2,
          }}
        >
          <div>
            <Label tag="textsmall_strong">Site</Label>
            <Typography tag="textsmall">
              {
                selectedScenario.data.sites.byId[selectedItem.SITE_ID.value]
                  .SITE.value
              }
            </Typography>
          </div>
        </div>

        <Label tag="textsmall_strong">Resource</Label>
        <Input
          size="small"
          id="RESOURCE_NAME"
          defaultValue={form.RESOURCE_NAME.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 20,
          }}
        >
          <div>
            <Label marginTop={formMargin} tag="textsmall_strong">
              Grade
            </Label>

            <Select
              onChange={(event) => handleInputChange(event)}
              size="small"
              defaultValue={form.RESOURCE_GRADE_ID.value}
              id="RESOURCE_GRADE_ID"
              disabled={resourceGrades.length <= 1}
            >
              {resourceGrades.map((el: TSelect) => {
                return (
                  <SelectOption key={el.id} label={el.name} value={el.id} />
                );
              })}
            </Select>
          </div>
          <div>
            <Label marginTop={formMargin} tag="textsmall_strong">
              Building
            </Label>

            <Select
              onChange={(event) => handleInputChange(event)}
              size="small"
              defaultValue={form.BUILDING_ID.value}
              id="BUILDING_ID"
              disabled={resourceBuildings.length <= 1}
            >
              {resourceBuildings.map((el: TSelect) => {
                return (
                  <SelectOption key={el.id} label={el.name} value={el.id} />
                );
              })}
            </Select>
          </div>
          <div>
            <Label marginTop={formMargin} tag="textsmall_strong">
              Shift
            </Label>

            <Select
              onChange={(event) => handleInputChange(event)}
              size="small"
              defaultValue={form.RESOURCE_SHIFT_CALENDAR_ID.value}
              id="RESOURCE_SHIFT_CALENDAR_ID"
              disabled={shiftCalendar.length <= 1}
            >
              {shiftCalendar.map((el: TSelect) => {
                return (
                  <SelectOption key={el.id} label={el.name} value={el.id} />
                );
              })}
            </Select>
          </div>
        </div>

        <Label marginTop={formMargin} tag="textsmall_strong">
          Max Capacity
        </Label>
        <Slider
          id="RESOURCE_MAX_CAPACITY"
          defaultValue={form.RESOURCE_MAX_CAPACITY.value}
          min={1}
          max={100}
          sliderType="minimized"
          width={'420px'}
          onChange={(event) => handleInputChange(event)}
        />

        <Label marginTop={formMargin} tag="textsmall_strong">
          Availability
        </Label>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
          <div>
            <Label tag="textsmall">Illness</Label>
            <Slider
              id="RESOURCE_AVAILABILITY_ILLNESS"
              defaultValue={form.RESOURCE_AVAILABILITY_ILLNESS.value}
              min={1}
              max={100}
              sliderType="minimized"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Label tag="textsmall">Tech</Label>
            <Slider
              id="RESOURCE_AVAILABILITY_TECH"
              defaultValue={form.RESOURCE_AVAILABILITY_TECH.value}
              min={1}
              max={100}
              sliderType="minimized"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Label tag="textsmall">Vacation</Label>
            <Slider
              id="RESOURCE_AVAILABILITY_VACATION"
              defaultValue={form.RESOURCE_AVAILABILITY_VACATION.value}
              min={1}
              max={100}
              sliderType="minimized"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
        </div>

        <Label marginTop={formMargin} tag="textsmall_strong">
          Mean Time to Repair
        </Label>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div>
            <Label tag="textsmall">Illness</Label>
            <FormResourcesTimePicker
              days={MTTR_Illness.days}
              hours={MTTR_Illness.hours - MTTR_Illness.days * 24}
              id="illness"
              onChange={(value) =>
                handleTimeChange(
                  'RESOURCE_MTTR_ILLNESS',
                  formatSecondsToHours(value),
                )
              }
            />
          </div>
          <div>
            <Label tag="textsmall" marginTop={20}>
              Tech
            </Label>
            <FormResourcesTimePicker
              days={MTTR_Tech.days}
              hours={MTTR_Tech.hours - MTTR_Tech.days * 24}
              id="tech"
              onChange={(value) =>
                handleTimeChange(
                  'RESOURCE_MTTR_TECH',
                  formatSecondsToHours(value),
                )
              }
            />
          </div>
          <div>
            <Label tag="textsmall" marginTop={20}>
              Vacation
            </Label>
            <FormResourcesTimePicker
              days={MTTR_Vacation.days}
              hours={MTTR_Vacation.hours - MTTR_Vacation.days * 24}
              id="vacation"
              onChange={(value) =>
                handleTimeChange(
                  'RESOURCE_MTTR_VACATION',
                  formatSecondsToHours(value),
                )
              }
            />
          </div>
        </div>
        {/*   <pre>
          {JSON.stringify(
            selectedScenario?.data.production_orders.byId[
              simulationParams.formItem
            ],
            null,
            2,
          )}
        </pre> */}

        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormResources;
