import { useState } from 'react';

/**
 * useUploadFile Hook
 * Manages the state and operation for uploading the Snowflake PPR file.
 * Uses the provided URL and access token for the API request.
 *
 * @param {string} url - The base URL for the API
 * @param {string} accessToken - The access token for authorization
 */
const useUploadFile = (url: string, accessToken:string) => {
  const [fileId, setFileId] = useState(null);

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${url}/webapi/user/v2/inputfiles`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFileId(data);
      return data;
    } catch (error) {
      console.error('File upload failed');
    }
  };

  return { uploadFile, fileId };
};

export default useUploadFile;