import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

interface ApiConfig {
  baseUrl: string;
  accessToken?: string;
}

/**
 * ApiCall Function
 * Makes an API call using Axios with the specified method, endpoint, and configuration
 * Supports optional access token for authorization and custom headers
 * Handles payload and response type configuration
 *
 * @param {Method} method - The HTTP method for the API call (e.g., GET, POST)
 * @param {string} endpoint - The API endpoint to be called
 * @param {ApiConfig} config - The API configuration including baseUrl and optional accessToken
 * @param {AxiosRequestConfig['headers']} headers - Custom headers for the API call
 * @param {unknown} [payload] - The payload to be sent with the request (optional)
 * @param {AxiosRequestConfig['responseType']} [responseType='json'] - The expected response type (optional)
 * @returns {Promise<AxiosResponse>} - The response from the API call
 */
const ApiCall = async (
  method: Method,
  endpoint: string,
  { baseUrl, accessToken }: ApiConfig,
  headers: AxiosRequestConfig['headers'],
  payload?: unknown,
  responseType: AxiosRequestConfig['responseType'] = 'json',
): Promise<AxiosResponse> => {
  const url = baseUrl + endpoint;

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: accessToken,
    };
  }

  const config: AxiosRequestConfig = {
    method,
    url,
    headers,
    data: payload,
    responseType,
  };

  return await axios(config);
};

export default ApiCall;
