/**
 * convertStringToDate Function
 * Converts a date string into a formatted string (YYMMDD)
 * Parses the input date string to create a Date object
 * Extracts the year, month, and day, and formats them as two-digit strings
 * Returns the formatted date string
 *
 * @param {string} dateString - The date string to be converted
 * @returns {string} - The formatted date string in YYMMDD format
 */
export const convertStringToDate = (dateString: string) => {
  const date = new Date(dateString);

  const year = date.getFullYear().toString().substring(2, 4);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const result = year + month + day;
  return result;
};
