import useAccessToken from '@/hooks/useAccessToken';
import useUserDetails from '@/hooks/useUserDetails';
import { ApiConnector } from '@/services/ApiConnector';
import { convertStringToDate, slugify } from '@/utils';
import {
  ButtonIcon,
  Table,
  Tooltip,
} from '@data-products-and-ai/react-components';
import { TableHeaderType } from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { useEffect, useState } from 'react';
import { SimulationListGroupedItemType, SimulationListItemType } from './types';
import { groupData } from './utils';
import { generateUID } from '@/utils/helpers';

const TableHeaders: TableHeaderType[] = [
  { title: 'Simulation', align: 'left' },
  { title: 'Status', align: 'left' },
  { title: 'Start Date', align: 'left' },
  { title: 'Time to Complete', align: 'left' },
  { title: 'Retries', align: 'center' },
  { title: '', align: 'right' },
];

/**
 * SimulationList Component
 * Renders a table of simulations with details and a download button
 * Fetches simulation data from the API and manages the download of simulation files
 * Uses the access token and user details hooks
 * Manages the state for the simulation list and its loading status
 */
const SimulationList = () => {
  const { gid } = useUserDetails();
  const { getSilentToken } = useAccessToken();
  const apiBaseUrl = import.meta.env.VITE_API_URL;
  const [simulationList, setSimulationList] = useState<
    SimulationListItemType[]
  >([]);
  const [simulationListLoaded, setSimulationListLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    const SimulationsFetch = async () => {
      try {
        const token = await getSilentToken();

        const response = await ApiConnector(
          'GET',
          `users/${gid}/simulations`,
          { baseUrl: apiBaseUrl, accessToken: token },
          { 'Content-Type': 'application/json', Accept: 'application/json' },
        );
        console.log('Sim Fetched', response.data.message);
        setSimulationList(response.data.message);
        setSimulationListLoaded(true);
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    SimulationsFetch();
    const interval = setInterval(async () => {
      try {
        console.log('Sim Fetched');
        SimulationsFetch();
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    }, 60000);

    return () => {
      console.log('Interval cleared');
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Downloads the gzipped JSON data as a file
   * Creates a Blob from the binary data and triggers a download
   * @param {ArrayBuffer} binaryString - The binary data to download
   * @param {string} filename - The name of the file to be downloaded
   */
  const downloadGzippedJSON = (binaryString: ArrayBuffer, filename: string) => {
    const blob = new Blob([binaryString], {
      type: 'application/octet-stream',
    });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up the temporary anchor element
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  /**
   * Handles the download of a simulation file
   * Fetches the simulation data from the API and triggers the download
   * @param {SimulationListGroupedItemType} item - The simulation item to download
   */
  const handleDownload = async (item: SimulationListGroupedItemType) => {
    const token = await getSilentToken();

    ApiConnector(
      'GET',
      `simulations?date=${convertStringToDate(item.startTime)}&id=${item.id}`,
      { baseUrl: apiBaseUrl, accessToken: token },
      { 'Content-Type': 'application/json', Accept: '*/*' },
      null,
      'arraybuffer',
    ).then((value) => {
      if (!item.title) item.title = 'FDTS';
      downloadGzippedJSON(value.data, `${slugify(item.title)}.twbx`);
    });
  };

  return (
    <>
      <div style={{ padding: 20, fontFamily: 'Siemens' }}>
        {!simulationListLoaded ? (
          'Loading...'
        ) : (
          <Table
            headers={TableHeaders}
            width={'100%'}
            isSelectable={false}
            typographyTag="textsmall"
            stickyHeader={true}
            headerVerticalAlign="bottom"
            noResultsMessage="No simulations available"
            rows={groupData(simulationList).map(
              (item: SimulationListGroupedItemType) => ({
                id: item.id,
                data: {
                  title: (
                    <Tooltip
                      key={generateUID()}
                      title={item.id}
                      disableInteractive={false}
                    >
                      <div style={{ display: 'inline-block' }}>
                        {item.title}
                      </div>
                    </Tooltip>
                  ),

                  progress: item.progress,
                  startTime: item.startTime,
                  endTime: item.endTime,
                  retries: item.retries,

                  download:
                    item.progress === 'Succeeded' ? (
                      <ButtonIcon
                        hoverType="color"
                        icon="IconDownload"
                        onClick={() => handleDownload(item)}
                        size="small"
                        tooltipMessage="Download"
                      />
                    ) : null,
                },
              }),
            )}
            onRowClick={(rowData) => console.log('Row clicked', rowData)}
            onRowRightClick={(rowData) =>
              console.log('Row right-clicked', rowData)
            }
          />
        )}
      </div>
    </>
  );
};

export default SimulationList;
