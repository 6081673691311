/* eslint-disable @typescript-eslint/no-explicit-any */
// utils/hashNumber.ts
import * as CryptoJS from 'crypto-js';

/**
 * convertToLocalTime Function
 * Converts a UTC date string to the local time string
 * Appends 'Z' to the date string to indicate UTC time
 * Creates a Date object and converts it to a locale-specific string
 *
 * @param {string} dateString - The UTC date string to be converted
 * @returns {string} - The local time string
 */
export function convertToLocalTime(dateString: string) {
  const date = new Date(dateString + 'Z');
  return date.toLocaleString();
}

/**
 * getTimeInterval Function
 * Calculates the time interval between two date strings
 * Parses the input date strings to create Date objects
 * Computes the difference in milliseconds and converts it to minutes and seconds
 * Returns the formatted time interval string
 *
 * @param {string} dateString1 - The first date string
 * @param {string} dateString2 - The second date string
 * @returns {string | null} - The formatted time interval string or null if inputs are invalid
 */
export function getTimeInterval(dateString1: string, dateString2: string) {
  if (!dateString1 || !dateString2) return null;

  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const milliseconds = Math.abs(date2.valueOf() - date1.valueOf());
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes} minutes and ${seconds
    .toString()
    .padStart(2, '0')} seconds`;
}

/**
 * generateUID Function
 * Generates a unique identifier (UID)
 * Combines the current timestamp with a random number to ensure uniqueness
 * Returns the generated UID as a string
 *
 * @returns {string} - The unique identifier
 */
export const generateUID = (): string => {
  const UT = new Date().valueOf();
  const Salt = Math.floor(Math.random() * 100000);
  const UID = `${UT}${Salt}`;

  return UID;
};

/**
 * hashNumber Function
 * Hashes a number or string using SHA-256 and returns the hash as a string
 * Optionally truncates the hash to a specified length
 * Deprecated: Previous implementation returned a number instead of a string
 *
 * @param {number | string} number - The number or string to be hashed
 * @param {number} [minLength=16] - The minimum length of the truncated hash
 * @param {number} [maxLength=38] - The maximum length of the truncated hash
 * @returns {string} - The hashed string
 */
export function hashNumber(
  number: number | string,
  minLength: number = 16,
  maxLength: number = 38,
): string {
  const hash = CryptoJS.SHA256(number.toString()).toString(CryptoJS.enc.Hex);

  return hash;
  //deprecated - returned a number instead of string
  // Determine the length of the truncated hash based on minLength and maxLength
  const hashLength = Math.min(Math.max(minLength, 16), maxLength);
  const numCharsToTruncate = Math.ceil((hashLength * 4) / 5); // SHA-256 produces 4 bits of output per 5 bits of input

  // Truncate the hash to get the desired length
  const truncatedHash = hash.slice(0, numCharsToTruncate);
  const hashedBigInt = BigInt(`0x${truncatedHash}`);

  return hashedBigInt.toString();
  return hashedBigInt.toString().padStart(hashLength, '0');
}

type AllowedDateTypes =
  | 'yyyy/mm/dd'
  | 'dd/mm/yyyy'
  | 'mm/yyyy'
  | 'yyyy.mm.dd'
  | 'dd.mm.yyyy'
  | 'mm.yyyy';

type TTime = {
  time: string;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalSeconds: number;
};

/**
 * formatHoursToTime Function
 * Converts a number of hours into a detailed time object
 * Calculates total seconds, days, hours, minutes, and seconds
 * Returns a formatted time string and a TTime object
 *
 * @param {number} hours - The number of hours to be converted
 * @returns {TTime} - An object containing the formatted time string and individual time components
 */
export function formatHoursToTime(hours: number): TTime {
  let totalSeconds = hours * 3600;

  if (hours <= 0) {
    totalSeconds = 0;
  } else {
    totalSeconds = hours * 3600;
  }

  const daysValue = Math.floor(totalSeconds / 3600 / 24);
  const hoursValue = Math.floor(totalSeconds / 3600);
  const minutesValue = Math.floor((totalSeconds % 3600) / 60);
  const secondsValue = Math.floor(totalSeconds % 60);

  return {
    time:
      hours <= 0 ? 'N/A' : `${hoursValue}h ${minutesValue}m ${secondsValue}s`,
    days: daysValue,
    hours: hoursValue,
    minutes: minutesValue,
    seconds: secondsValue,
    totalSeconds: Math.round(totalSeconds),
  };
}

export function formatSecondsToHours(seconds: number): number {
  return seconds / 3600;
}

/**
 * unixTimestampToDate Function
 * Converts a Unix timestamp to a formatted date string
 * Supports multiple date formats specified by the AllowedDateTypes type
 * Parses the input timestamp and formats the date components accordingly
 *
 * @param {number | string} unixTimestamp - The Unix timestamp to be converted
 * @param {AllowedDateTypes} [format] - The desired date format
 * @returns {string} - The formatted date string
 */
export function unixTimestampToDate(
  unixTimestamp: number | string,
  format?: AllowedDateTypes,
): string {
  //Check if a number or a string
  const timestampInMilliseconds =
    typeof unixTimestamp === 'number'
      ? unixTimestamp * 1000
      : parseInt(unixTimestamp) * 1000;

  // Multiply by 1000 to convert the Unix timestamp to milliseconds
  const date = new Date(timestampInMilliseconds);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
  const year = date.getUTCFullYear();

  //return Type is string - default
  if (format === 'yyyy/mm/dd') return `${year}/${month}/${day}`;
  if (format === 'mm/yyyy') return `${month}/${year}`;
  if (format === 'yyyy.mm.dd') return `${year}.${month}.${day}`;
  if (format === 'mm.yyyy') return `${month}.${year}`;
  if (format === 'dd.mm.yyyy') return `${day}.${month}.${year}`;

  return `${day}/${month}/${year}`;
}

/**
 * sortTree Function
 * Recursively sorts a tree structure alphabetically by the title property
 * Sorts the current level of the tree and then recursively sorts the children
 *
 * @param {any[]} treeData - The tree data to be sorted
 * @returns {any[]} - The sorted tree data
 */
const sortTree = (treeData: any[]): any[] => {
  // Sort the current level alphabetically by title

  treeData.sort((a: any, b: any) => {
    const titleA = a.title.trim().toLowerCase();
    const titleB = b.title.trim().toLowerCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  // Recursively sort the children
  treeData.forEach((item: any) => {
    if (Array.isArray(item.children) && item.children.length > 0) {
      sortTree(item.children);
    }
  });
  return treeData;
};