import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';

type LoaderType = {
  message: string | MessageSwitcherProps;
  time?: number;
};

export interface MessageSwitcherProps {
  messages: string[];
  time: number;
}

/**
 * MessageSwitcher Component
 * Switches between messages at a specified interval.
 * Manages the state for the current message index and updates it periodically.
 *
 * @param {MessageSwitcherProps} obj - The properties for the message switcher component
 */
const MessageSwitcher = (obj: MessageSwitcherProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const { messages, time } = obj;
  const interval = time / messages.length;

  useEffect(() => {
    const switchMessage = () => {
      setCurrentMessageIndex((prevIndex) => {
        if (prevIndex < messages.length - 1) {
          return prevIndex + 1;
        } else {
          return prevIndex;
        }
      });
    };

    const timer = setInterval(switchMessage, interval);

    return () => clearInterval(timer);
  }, [messages, interval]);

  return <div>{messages[currentMessageIndex]}</div>;
};

/**
 * Loader Component
 * Displays a loading screen with a circular progress indicator and a message.
 * Supports both string messages and message switcher objects.
 * @param {LoaderType} props - The properties for the loader component
 */
const Loader = (props: LoaderType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isString = (value: any): value is string => {
    return typeof value === 'string';
  };
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 90000,
        opacity: 0.9,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <div>
          <div
            style={{
              textAlign: 'center',
              color: '#8a00e5',
              marginBottom: 20,
            }}
          >
            <CircularProgress color="inherit" />
          </div>
          <div style={{ width: 800, textAlign: 'center' }}>
            {isString(props.message) ? (
              props.message
            ) : (
              <MessageSwitcher
                messages={props.message.messages}
                time={props.message.time}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
