import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { useContext } from 'react';
import { FormProductionOrders } from './FormProductionOrders';
import { TSimulationMenuOptions } from '@/store/types';
import { FormProducts } from './FormProducts';
import { FormOperations } from './FormOperations';
import { FormOperationsSteps } from './FormOperationsSteps';
import { FormResources } from './FormResources';
import { FormShifts } from './FormShifts';
// import { FormShutdowns } from './FormShutdowns';
import { FormOperationsResources } from './FormOperationsResources';
import { FormShutdowns } from '@/pages/Simulation/FormView/FormShutdowns';

/**
 * TableView Component
 * Renders the appropriate form based on the selected simulation menu option
 * Uses the simulation parameters from the SimulationCtx context
 *
 * @returns {JSX.Element} - The rendered component
 */
const TableView = () => {
  const { simulationParams } = useContext(SimulationCtx);

  /**
   * ShowForm Function
   * Returns the appropriate form component based on the selected simulation menu option
   *
   * @param {TSimulationMenuOptions} selectedOption - The selected simulation menu option
   * @returns {JSX.Element | null} - The form component to be rendered or null if no option is selected
   */
  const ShowForm = (selectedOption: TSimulationMenuOptions) => {
    switch (selectedOption) {
      case 'production_orders':
        return <FormProductionOrders />;
      case 'products':
        return <FormProducts />;
      case 'operations':
        return <FormOperations />;
      case 'operation_steps':
        return <FormOperationsSteps />;
      case 'operations_resources':
        return <FormOperationsResources />;
      case 'resources':
        return <FormResources />;
      case 'shifts':
        return <FormShifts />;
      case 'shutdowns':
        return <FormShutdowns />;

      default:
        return null;
    }
  };

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      {/*  {simulationParams.formArea} */}
      {simulationParams.formArea && ShowForm(simulationParams.formArea)}
    </div>
  );
};

export default TableView;
