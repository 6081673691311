/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Sorts the children of each object in the array by their title property in ascending order
 * Recursively sorts the children of children
 * @param {any[]} objects - The array of objects to sort
 * @returns {any[]} - The sorted array of objects
 */
export function sortChildrenByTitleAsc(objects: any[]): any[] {
  objects.forEach((obj) => {
    if (obj.children) {
      obj.children.sort((a: any, b: any) => {
        const titleA = (a.data.title as string) ?? '';
        const titleB = (b.data.title as string) ?? '';
        return titleA.localeCompare(titleB);
      });
      // Recursively sort children of children
      sortChildrenByTitleAsc(obj.children);
    }
  });
  return objects;
}
