import { css, styled } from 'styled-components';

export const Scenario = styled.div<{ $is_selected: boolean }>`
    position: relative;
    font-size: 12px;
    padding: 8px 15px 8px 20px;
    cursor: pointer;
    border-left: solid 4px transparent;
    ${(props) =>
            !props.$is_selected &&
            css`
                &:hover {
                    background-color: #ededed;
                }
            `}

    ${(props) =>
            props.$is_selected &&
            css`
      background-color: #e6e6e6;
      border-left: solid 4px #8a00e5 !important;
    `}
`;
