/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AdditionalEquipment,
  Enterprise,
  Operation,
  OperationResource,
  OperationTypes,
  PauseShift,
  ProcessTypes,
  Product,
  ProductClass,
  ProductionOrder,
  ResouceBuilding,
  Resource,
  ResourceClass,
  ResourceGrade,
  ResourceLifecycle,
  ResourceLifecycleStatus,
  ResourceName,
  ResourceSubClass,
  ResourceType,
  SetAdditionalEquipment,
  SetWorkUnit,
  Shift,
  ShiftCalendar,
  Site,
  Wip,
  WorkPlan,
  Skills,
  Personnel,
  PersonnelGroup,
  Shutdown,
  OperationsSkills,
  PersonnelSkills,
  PersonnelByGroups,
  Pause,
  DistinctPersonnelGroup,
} from '@/store/types';

type Item = {
  [key: string]: any;
  parent?: string;
  is_hidden: boolean;
  is_wip: boolean;
  is_disabled: boolean;
  is_deleted: boolean;
  is_open: boolean;
  is_selected: boolean;
  is_selectable: boolean;
  is_match: boolean;
  children: string[];
};

type ById = {
  [key: string]: Item;
};

type TResult<T extends ById> = {
  byId: T;
  allIds: string[];
};

/**
 * Tree Generation and Manipulation Functions
 * Provides functions to create and manipulate tree structures from various data types
 * Includes functions to create trees, add children, and handle different data types
 */
function createTree<T>(
  data: any[],
  idKey: keyof T,
  parentKey: keyof T | null,
  otherKeys: Array<keyof T> = [],
): T {
  const result: TResult<ById> = {
    byId: {},
    allIds: [],
  };

  for (let i = 0; i < data.length; i++) {
    const item: Item = {
      [idKey]: {
        value: data[i][idKey].value,
        originalValue: data[i][idKey].value,
      },
      is_wip: true,
      is_hidden: false,
      is_disabled: false,
      is_deleted: false,
      is_open: false,
      is_selected: false,
      is_selectable: false,
      is_match: false,
      children: [],
    };

    if (parentKey !== null) {
      item[parentKey as string] = {
        value: data[i][parentKey as string],
        originalValue: data[i][parentKey as string],
      };
    }

    for (const key of otherKeys) {
      try {
        item[key as string] = {
          value: data[i][key as string].value,

          originalValue: data[i][key as string].value,
        };
      } catch (e) {
        console.error('Error creating tree:', key, e);
      }
    }

    if (parentKey !== null) item.parent = data[i][parentKey].value;

    result.byId[data[i][idKey].value] = item;
    result.allIds.push(data[i][idKey].value);
  }

  //second loop filling the children if parentKey exists
  if (parentKey !== null) {
    for (let i = 0; i < data.length; i++) {
      if (
        data[i][parentKey].value !== null &&
        result.byId[data[i][parentKey].value]
      ) {
        result.byId[data[i][parentKey].value].children.push(
          data[i][idKey].value,
        );
      }
    }
  }
  return result as T;
}

function createTreeChildren<T>(
  data: TResult<ById>,
  parentData: TResult<ById>,
  idKey: keyof T,
): TResult<ById> {
  console.log('PARENT DATA', parentData);

  for (let i = 0; i < data.allIds.length; i++) {
    //get workplan id of current operation
    const temp = data.byId[data.allIds[i]][idKey as string].originalValue;
    if (parentData.byId[temp] !== undefined) {
      parentData.byId[temp].children.push(data.allIds[i]);
    }
  }

  return parentData;
}

type WipKeys = keyof Wip;

type TreeGenerator = {
  [K in WipKeys]: (data: any) => any;
};

type TreeChildrenGenerator = {
  [K in WipKeys]: (data: any, parentData: any) => any;
};

export const generateTree: TreeGenerator = {
  production_orders: (data: ProductionOrder[]) =>
    createTree<ProductionOrder>(
      data,
      'PRODUCTION_ORDER_ID',
      'PARENT_PRODUCTION_ORDER_ID',
      [
        'PARENT_PRODUCTION_ORDER_ID',
        'PRODUCTION_ORDER',
        'PO_END_DATE_TIMESTAMP',
        'PO_START_DATE_TIMESTAMP',
        'QUANTITY_ASSEMBLED_PARTS',
        'SITE_ID',
        'WORK_PLAN_ID',
        'PRODUCT_ID',
        'END_PRODUCTION_ORDER_ID',
        'END_PRODUCT_ID',
        'PO_LAST_STEP_WIP',
        'PO_QUANTITY',
      ],
    ),

  products: (data: Product[]) =>
    createTree<Product>(data, 'PRODUCT_ID', 'PARENT_PRODUCT_ID', [
      'PARENT_PRODUCT_ID',
      'PRODUCT',
      'PRODUCT_CLASS',
      'PRODUCT_SUBCLASS',
      'END_PRODUCT_ID',
      'SITE_ID',
    ]),
  additional_equipments: (data: AdditionalEquipment[]) =>
    createTree<AdditionalEquipment>(data, 'ADDITIONAL_EQUIPMENT_ID', null, [
      'ADDITIONAL_EQUIPMENT',
      'ADDITIONAL_EQUIPMENT_AVAILABILITY_ILLNESS',
      'ADDITIONAL_EQUIPMENT_AVAILABILITY_TECH',
      'ADDITIONAL_EQUIPMENT_AVAILABILITY_VACATION',
      'ADDITIONAL_EQUIPMENT_CLASS',
      'ADDITIONAL_EQUIPMENT_GRADE',
      'ADDITIONAL_EQUIPMENT_MAX_CAPACITY',
      'ADDITIONAL_EQUIPMENT_MTTR_ILLNESS',
      'ADDITIONAL_EQUIPMENT_MTTR_TECH',
      'ADDITIONAL_EQUIPMENT_MTTR_VACATION',
      'ADDITIONAL_EQUIPMENT_SUB_CLASS',
      'SHIFT_CALENDAR_ID',
      'SITE_ID',
    ]),

  operations: (data: Operation[]) =>
    createTree<Operation>(data, 'OPERATION_ID', null, [
      'BATCH_BLK_SUFFIX',
      'OPERATION',
      'OPERATION_BUSINESS',
      'OPERATION_TYPE',
      'PROCESS',
      'OPERATION_TYPE_ID',
      'PROCESS_TYPE_ID',
      'SITE_ID',
      'STEP',
      'WORK_PLAN_ID',
      'OPERATION_GOOD',
      'OPERATION_SCRAP',
      'OPERATION_REWORK',
      'OPERATION_INITIAL_START_TIME',
      'OPERATION_INITIAL_DURATION',
      'OPERATION_MIDDLE_START_TIME',
      'OPERATION_MIDDLE_DURATION',
      'OPERATION_END_START_TIME',
      'OPERATION_END_DURATION',
    ]),

  set_additional_equipments: (data: SetAdditionalEquipment[]) =>
    createTree<SetAdditionalEquipment>(data, 'ADDITIONAL_EQUIPMENT_ID', null, [
      'ADDITIONAL_EQUIPMENT_PRIORITY',
      'SITE_ID',
    ]),

  sites: (data: Site[]) =>
    createTree<Site>(data, 'SITE_ID', null, [
      'SITE_ID',
      'SITE',
      'SITE_ABBREVIATION',
      'ENTERPRISE_ID',
      'CITY',
      'COUNTRY',
    ]),

  set_work_units: (data: SetWorkUnit[]) =>
    createTree<SetWorkUnit>(data, 'SET_OF_WORK_UNIT_ID', null, [
      'SITE_ID',
      'WORK_UNIT_ID',
      'WORK_UNIT_PRIORITY',
    ]),

  shift_calendar: (data: ShiftCalendar[]) =>
    createTree<ShiftCalendar>(data, 'RESOURCE_SHIFT_CALENDAR_ID', null, [
      'RESOURCE_SHIFT_CALENDAR',
      'SITE_ID',
    ]),

  shifts: (data: Shift[]) =>
    createTree<Shift>(data, 'SHIFT_ID', null, [
      'END_TIME',
      'FR',
      'MO',
      'SA',
      'SHIFT_CALENDAR_ID',
      'SHIFT',
      'SITE_ID',
      'START_TIME',
      'SU',
      'TH',
      'TU',
      'WE',
      'SHIFT_HANDOVER_START_TIME',
      'SHIFT_HANDOVER_END_TIME',
    ]),
  shutdowns: (data: Shutdown[]) =>
    createTree<Shutdown>(data, 'SHUTDOWN_ID', null, [
      'REDUCED_ON',
      'REDUCED_TIME',
      'SHUTDOWN_DATE',
      'SHUTDOWN_TITLE',
      'SHUTDOWN_TYPE',
      'SITE_ID',
      'WORKING_OR_NOT',
    ]),

  work_plans: (data: WorkPlan[]) =>
    createTree<WorkPlan>(data, 'WORK_PLAN_ID', null, ['WORK_PLAN', 'SITE_ID']),

  workplan_processes: (data: any) => {
    console.log(data);
  },
  workunit_classes: (data: any) => {
    console.log(data);
  },
  additionalequipment_classes: (data: any) => {
    console.log(data);
  },

  process_types: (data: ProcessTypes[]) =>
    createTree<ProcessTypes>(data, 'PROCESS_TYPE_ID', null, [
      'PROCESS',
      'SITE_ID',
    ]),

  operation_types: (data: OperationTypes[]) =>
    createTree<OperationTypes>(data, 'OPERATION_TYPE_ID', null, [
      'OPERATION',
      'SITE_ID',
    ]),

  resources: (data: Resource[]) =>
    createTree<Resource>(data, 'RESOURCE_ID', null, [
      'BUILDING_ID',
      'RESOURCE_AVAILABILITY_ILLNESS',
      'RESOURCE_AVAILABILITY_TECH',
      'RESOURCE_AVAILABILITY_VACATION',
      'RESOURCE_CLASS_ID',
      'RESOURCE_GRADE_ID',
      'RESOURCE_MAX_CAPACITY',
      'RESOURCE_MTTR_ILLNESS',
      'RESOURCE_MTTR_TECH',
      'RESOURCE_MTTR_VACATION',
      'RESOURCE_NAME',
      'RESOURCE_SHIFT_CALENDAR_ID',
      'RESOURCE_SHIFT_CALENDAR',
      'RESOURCE_SUBCLASS_ID',
      'SITE_ID',
      'RESOURCE_GOOD',
      'RESOURCE_SCRAP',
      'RESOURCE_REWORK',
      'RESOURCE_MAX_WORKER_CAPACITY',
    ]),

  resource_lifecycle: (data: ResourceLifecycle[]) =>
    createTree<ResourceLifecycle>(data, 'RESOURCE_LIFECYCLE_ID', null, [
      'RESOURCE_LIFECYCLE_ID',
      'RESOURCE_ID',
      'RESOURCE_LIFECYCLE_STATUS_ID',
      'RESOURCE_LIFECYCLE_STATUS_MOVED_RESOURCE_ID',
      'RESOURCE_LIFECYCLE_STATUS',
      'RESOURCE_LIFECYCLE_STATUS_START_TIMESTAMP',
      'RESOURCE_LIFECYCLE_STATUS_END_TIMESTAMP',
      'RESOURCE_LIFECYCLE_STATUS_DISTANCE',
      'SITE_ID',
    ]),
  resource_lifecycle_status: (data: ResourceLifecycleStatus[]) =>
    createTree<ResourceLifecycleStatus>(
      data,
      'RESOURCE_LIFECYCLE_STATUS_ID',
      null,
      ['RESOURCE_LIFECYCLE_STATUS', 'SITE_ID'],
    ),

  resource_names: (data: ResourceName[]) =>
    createTree<ResourceName>(data, 'RESOURCE_NAME_ID', null, [
      'RESOURCE_NAME',
      'SITE_ID',
    ]),

  resource_types: (data: ResourceType[]) =>
    createTree<ResourceType>(data, 'RESOURCE_TYPE_ID', null, [
      'RESOURCE_TYPE',
      'SITE_ID',
    ]),

  resource_classes: (data: ResourceClass[]) =>
    createTree<ResourceClass>(data, 'RESOURCE_CLASS_ID', null, [
      'RESOURCE_CLASS',
    ]),

  resource_sub_classes: (data: ResourceSubClass[]) =>
    createTree<ResourceSubClass>(data, 'RESOURCE_SUBCLASS_ID', null, [
      'RESOURCE_SUBCLASS',
      'RESOURCE_CLASS_ID',
      'SITE_ID',
    ]),

  resource_grades: (data: ResourceGrade[]) =>
    createTree<ResourceGrade>(data, 'RESOURCE_GRADE_ID', null, [
      'RESOURCE_GRADE',
      'SITE_ID',
    ]),

  operations_resources: (data: OperationResource[]) =>
    createTree<OperationResource>(data, 'OPERATIONS_RESOURCES_ID', null, [
      'OPERATION_ID',
      'RESOURCE_ID',
      'RESOURCE_PRIORITY',
      'RESOURCE_TYPE_ID',
      'MAX_QUANTITY_IN_BATCH',
      'SETUP_TIME',
      'PROCESS_TIME',
      'PERSONNEL_TIME',
      /*  'SITE_ID', */
    ]),

  pause_shift: (data: PauseShift[]) =>
    createTree<PauseShift>(data, 'SHIFT_PAUSE_PK', null, [
      'PAUSE_ID',
      'SHIFT_ID',
      'SITE_ID',
      'SITE',
    ]),

  enterprises: (data: Enterprise[]) =>
    createTree<Enterprise>(data, 'ENTERPRISE_ID', null, ['ENTERPRISE']),

  product_classes: (data: ProductClass[]) =>
    createTree<ProductClass>(data, 'PRODUCT_CLASS_ID', null, [
      'PRODUCT_CLASS',
      'SITE_ID',
    ]),
  resource_buildings: (data: ResouceBuilding[]) =>
    createTree<ResouceBuilding>(data, 'BUILDING_ID', null, [
      'BUILDING',
      'SITE_ID',
    ]),
  skills: (data: Skills[]) =>
    createTree<Skills>(data, 'SKILL_ID', null, ['SKILL', 'SITE_ID']),
  personnel: (data: Personnel[]) =>
    createTree<Personnel>(data, 'PERSON_ID', null, [
      'PERSON_NAME',
      'SITE',
      'SITE_ID',
      'SITE_ID',
      'WIP_ID',
    ]),
  personnel_group: (data: PersonnelGroup[]) =>
    createTree<PersonnelGroup>(data, 'PERSONNEL_GROUP_ID', null, [
      'PERSONNEL_GROUP_ID',
      'PERSONNEL_GROUP',
      'SITE_ABBREVIATION',
      'RESOURCE_CLASS_ID',
      'RESOURCE_ID',
      'RESOURCE_SUBCLASS_ID',
      'SITE',
      'SITE_ID',
      'WIP_ID',
    ]),

  personnel_skills: (data: PersonnelSkills[]) =>
    createTree<PersonnelSkills>(data, 'PERSON_SKILL_PK', null, [
      'PERSON_ID',
      'PERSON_SKILL_PRIORITY',
      'SITE',
      'SITE_ABBREVIATION',
      'SITE_ID',
      'SKILL_ID',
      'WIP_ID',
    ]),
  operations_skills: (data: OperationsSkills[]) =>
    createTree<OperationsSkills>(data, 'OPERATIONS_SKILL_PK', null, [
      'OPERATION_ID',
      'OPERATION_SKILL_MIN_WORKERS',
      'OPERATION_SKILL_PRIORITY',
      'SITE',
      'SITE_ABBREVIATION',
      'SITE_ID',
      'SKILL_ID',
      'WIP_ID',
    ]),
  personnel_by_groups: (data: PersonnelByGroups[]) =>
    createTree<PersonnelByGroups>(data, 'PERSON_BY_GROUP_PK', null, [
      'PERSONNEL_GROUP_ID',
      'PERSON_ID',
      'SITE',
      'SITE_ABBREVIATION',
      'SITE_ID',
      'WIP_ID',
    ]),
  pauses: (data: Pause[]) =>
    createTree<Pause>(data, 'PAUSE_ID', null, [
      'START_TIME',
      'END_TIME',
      'SITE',
      'SITE_ID',
    ]),
  distinct_personnel_group: (data: DistinctPersonnelGroup[]) =>
    createTree<DistinctPersonnelGroup>(data, 'PERSONNEL_GROUP_ID', null, [
      'PERSONNEL_GROUP',
    ]),
};

export const addTreeChildren: Pick<
  TreeChildrenGenerator,
  'shifts' | 'operations'
> = {
  shifts: (data: any, parentData: any) =>
    createTreeChildren<Shift>(data, parentData, 'SHIFT_CALENDAR_ID'),
  operations: (data: any, parentData: any) =>
    createTreeChildren<Operation>(data, parentData, 'WORK_PLAN_ID'),
};

/* export function createTree(data: any[], parentId = null): productionOrderType {
  const result: productionOrderType = {
    byId: {},
    allIds: [],
  };

  for (let i = 0; i < data.length; i++) {
    result.byId[data[i].PRODUCTION_ORDER_ID.value] = {
      PRODUCTION_ORDER_ID: data[i].PRODUCTION_ORDER_ID.value,
      PRODUCTION_ORDER: data[i].PRODUCTION_ORDER.value,
      PO_END_DATE_TIMESTAMP: data[i].PO_END_DATE_TIMESTAMP.value,
      PO_START_DATE_TIMESTAMP: data[i].PO_START_DATE_TIMESTAMP.value,
      QUANTITY_ASSEMBLED_PARTS: data[i].QUANTITY_ASSEMBLED_PARTS.value,
      SITE_ID: data[i].SITE_ID.value,
      WORK_PLAN_ID: data[i].WORK_PLAN_ID.value,
      PRODUCT_ID: data[i].PRODUCT_ID.value,
      PARENT_PRODUCTION_ORDER_ID: data[i].PARENT_PRODUCTION_ORDER_ID.value,
      parent: data[i].PARENT_PRODUCTION_ORDER_ID.value,
      children: [],
    };
    result.allIds.push(data[i].PRODUCTION_ORDER_ID.value);
  }

  for (let i = 0; i < data.length; i++) {
    if (
      data[i].PARENT_PRODUCTION_ORDER_ID.value !== null &&
      result.byId[data[i].PARENT_PRODUCTION_ORDER_ID.value]
    ) {
      result.byId[data[i].PARENT_PRODUCTION_ORDER_ID.value].children.push(
        data[i].PRODUCTION_ORDER_ID.value,
      );
    }
  }

  return result;
}
 */
