import { ScenarioSettings as TScenarioSettings } from '@/store/types';

/**
 * Handles the change of input fields in scenario settings
 * Updates the form state with the new input value
 * Supports different input types such as checkbox, range, and text
 * Handles special cases for specific parameter IDs
 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event - The input change event
 * @param {React.Dispatch<React.SetStateAction<TScenarioSettings>>} setForm - The function to set the form state
 * @param {number} [parameterIndex] - The index of the parameter set to update (optional)
 */
const handleInputChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setForm: React.Dispatch<React.SetStateAction<TScenarioSettings>>,
  parameterIndex?: number,
) => {
  const { id, value, type, checked } = event.target as HTMLInputElement & {
    id: string;
    value: string;
    type: string;
    checked: boolean;
  };

  const parts = id.split('___');
  const modified_id = parts.length > 1 ? parts[0] : id;

  const updateForm = (newValue: unknown) => {
    if (parameterIndex === undefined) {
      setForm((prevForm) => ({
        ...prevForm,
        [modified_id]: newValue,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        parameters: prevForm.parameters.map((param, index) => {
          if (index === parameterIndex) {
            return {
              ...param,
              [modified_id]: newValue,
            };
          }
          return param;
        }),
      }));
    }
  };

  if (type === 'checkbox') {
    updateForm(checked);
  } else if (
    modified_id === 'DropDown_JobsPrioritisation' ||
    modified_id === 'Variable_WaitOnBatchTimeAsProcessTimePercentage_value' ||
    modified_id === 'Fixed_WaitOnBatchTimeAsFixedHours_value' ||
    modified_id === 'DropDown_FileSelection' ||
    modified_id === 'Batch_Grouping_Option' ||
    modified_id === 'DropDown_Worker_Pooling' ||
    modified_id === 'DropDown_Scrap_Trigger' ||
    modified_id === 'DropDown_WaitOnBatchTime' ||
    type === 'range'
  ) {
    updateForm(Number(value));
  } else {
    updateForm(value);
  }
};

export { handleInputChange };
