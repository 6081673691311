import { LayoutCtx, MenuParamsType } from '@/contexts/Layout/LayoutCtx';
import { Footer, Header } from '@data-products-and-ai/react-components';
import { useEffect, useRef, useState } from 'react';
import styles from './Layout.module.scss';
interface LayoutProps {
  menu: React.ReactNode;
  content: React.ReactNode;
  contentMenu: React.ReactNode;
  contentMenuShow: boolean;
  menuCollapsed: boolean;
  menuWidth: string;
}

/**
 * Layout Component
 * Manages the layout parameters for the menu, content, header, and footer.
 * Uses context to provide layout parameters and update functions to its children.
 * @param {LayoutProps} props - The properties for the layout component
 */
const Layout = (props: LayoutProps) => {
  const [menuParams, setMenuParams] = useState<MenuParamsType>({
    height: '100vh',
    width: props.menuWidth,
    defaultWidth: props.menuWidth,
    collapsedWidth: '100px',
    top: '0px',
    isCollapsed: false,
  });

  const [contentParams, setContentParams] = useState({
    height: '100vh',
    width: '100%',
    top: '0px',
    left: props.menuWidth,
  });
  const [contentMenuParams, setContentMenuParams] = useState({
    height: '0px',
    width: '100%',
    top: '0px',
    left: props.menuWidth,
  });

  const [headerParams, setHeaderParams] = useState({
    height: '0px',
    width: '100%',
    top: '0px',
  });
  const [footerParams, setFooterParams] = useState({
    height: '0px',
    width: '100%',
    bottom: '0px',
  });

  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeaderParams((prevState) => ({
      ...prevState,
      height: headerRef.current ? `${headerRef.current.offsetHeight}px` : '0px',
    }));
    setFooterParams((prevState) => ({
      ...prevState,
      height: footerRef.current ? `${footerRef.current.offsetHeight}px` : '0px',
    }));
  }, []);

  useEffect(() => {
    setMenuParams((prevState) => ({
      ...prevState,
      height: `calc(100vh - ${headerParams.height} - ${footerParams.height})`,
      top: headerParams.height,
    }));

    setContentMenuParams((prevState) => ({
      ...prevState,
      width: `calc(100% - ${menuParams.width})`,
      top: headerParams.height,
      height: props.contentMenuShow ? '61px' : '0px',
    }));

    console.log('contentMenuShow ran', props.contentMenuShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerParams, footerParams, props.contentMenuShow]);

  useEffect(() => {
    setContentParams((prevState) => ({
      ...prevState,
      width: `calc(100% - ${menuParams.width})`,
      left: menuParams.width,
      top: `calc(${contentMenuParams.height} + ${menuParams.top})`,
      height: `calc(${menuParams.height} - ${contentMenuParams.height}`,
    }));
    console.log(contentMenuParams);
  }, [menuParams, contentMenuParams]);
  return (
    <LayoutCtx.Provider
      value={{
        menuParams,
        setMenuParams,
        contentParams,
        setContentParams,
        contentMenuParams,
        setContentMenuParams,
        headerParams,
        setHeaderParams,
        footerParams,
        setFooterParams,
      }}
    >
      <div className={styles.header} ref={headerRef}>
        {/* <Header /> */}
        <Header
          hasSignOut
          viewTicketsURL="https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/9fe75eaf-9a9e-4b73-a1d3-a3b47661c9c4?hidenavbar=true&Lookup=13"
          menuSupportURL="https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/89a0f1ea-22a2-4ace-a2d7-9725774347af?hidenavbar=true&Lookup=13"
        />
      </div>

      <div
        className={styles.menu}
        style={{
          top: menuParams.top,

          height: menuParams.height,

          width: menuParams.width,
        }}
      >
        {props.menu}
      </div>

      <div
        ref={contentMenuRef}
        className={styles.contentMenu}
        style={{
          top: contentMenuParams.top,
          height: contentMenuParams.height,
          left: contentMenuParams.left,
          width: contentMenuParams.width,
        }}
      >
        <div>{props.contentMenuShow ? props.contentMenu : null}</div>
      </div>

      <div
        className={styles.content}
        style={{
          top: contentParams.top,
          left: contentParams.left,
          height: contentParams.height,

          width: contentParams.width,
        }}
      >
        {props.content}
      </div>

      <div ref={footerRef} className={styles.footer}>
        <Footer
          appName="Factory Digital Twin Simulator"
          appVersion={'2.0.3 ' + import.meta.env.VITE_ENVIRONMENT}
          showRestricted={true}
        />
      </div>
    </LayoutCtx.Provider>
  );
};

export default Layout;
