import { Button, Tooltip } from '@data-products-and-ai/react-components';

/**
 * ScrapReworkPercentage Component
 * Displays a button indicating whether there is scrap or rework, if there is, displays a tooltip showing the percentages.
 *
 * @param {Object} props - The properties for the component
 * @param {string} props.itemId - The unique identifier for the item
 * @param {string} props.itemGood - The percentage of good
 * @param {string} props.itemScrap - The percentage of scrap
 * @param {string} props.itemRework - The percentage of rework
 * @param {boolean} props.is_open - Flag indicating whether the component is open
 */
const ScrapReworkPercentage = ({
  itemId,
  itemGood,
  itemScrap,
  itemRework,
  is_open,
}: {
  itemId: string;
  itemGood: string;
  itemScrap: string;
  itemRework: string;
  is_open: boolean;
}) => {
  if (!is_open) return '';

  return (
    <>
      <Tooltip
        key={'Tooltip_Operations_' + itemId}
        color="light"
        maxWidth={500}
        title={
          itemGood != '100' && (
            <div>
              <p> Scrap: {itemScrap}%</p>
              <p> Rework: {itemRework}% </p>
            </div>
          )
        }
        disableInteractive={false}
      >
        <div style={{ display: 'inline-block' }}>
          <Button type="headless" allCaps={false}>
            {itemGood == '100' ? 'NO' : 'YES'}
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

export default ScrapReworkPercentage;
