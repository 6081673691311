import {
  Button,
  Logo,
  Stack,
  useTranslation,
} from '@data-products-and-ai/react-components';
import styles from './Login.module.scss';
import Background from './assets/background.jpg';
import { AzureLoginButton } from './components/AzureLoginButton';
import { LoginTitle } from './components/LoginTitle';

/*
 * Login Component
 * Renders the login page with options for Azure login, requesting access, and support
 */
const Login = () => {
  const translate = useTranslation();

  /**
   * handleEmail Function
   * Creates a mailto link to open the user's default email client with a pre-filled subject
   * Uses the translate function to get the email subject text
   */
  const handleEmail = () => {
    const mail = document.createElement('a');
    mail.href = `mailto:pavel.gocev@siemens-energy.com?subject=${translate(
      'LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT',
    )}`; /* ${LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT} */
    mail.click();
    mail.remove();
  };

  const handleTicket = () => {
    window.open(
      'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/89a0f1ea-22a2-4ace-a2d7-9725774347af?hidenavbar=true&Lookup=13',
    );
  };

  return (
    <div
      className={styles.loginContainer}
      style={
        {
          '--bg-image': `url(${Background})`,
        } as React.CSSProperties
      }
    >
      <div className={styles.loginFormContainer}>
        <Logo background="light" size="medium" />
        <LoginTitle />

        <div style={{ marginTop: 15 }}>
          <AzureLoginButton />
        </div>

        <div className={styles.loginFormAdditional}>
          {/* Make_reusable divider  */}
          <hr />

          <Stack direction="row" horizontalAlign="center">
            <Button type="link" onClick={handleEmail} allCaps={false}>
              {translate('LOGIN.REQUEST_ACCESS')}
            </Button>

            {/* Make_reusable divider  */}
            <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>

            <Button type="link" onClick={handleTicket} allCaps={false}>
              {translate('LOGIN.SUPPORT')}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Login;
