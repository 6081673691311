import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { useContext } from 'react';
import SimulationNotStarted from './SimulationNotStarted/SimulationNotStarted';
import SimulationStarted from './SimulationStarted/SimulationStarted';

/**
 * LeftMenu Component
 * Renders either the SimulationStarted or SimulationNotStarted component based on the simulation state
 * Uses the simulation parameters from the SimulationCtx context
 *
 * @returns {JSX.Element} - The rendered component
 */
const LeftMenu = () => {
  const { simulationParams } = useContext(SimulationCtx);

  return simulationParams.simulationStarted ? (
    <SimulationStarted />
  ) : (
    <>
      <SimulationNotStarted />
    </>
  );
};

export default LeftMenu;
