import { Button, Icon, Typography } from '@data-products-and-ai/react-components';
import { Box, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import useOAuth from '@/hooks/useOAuth';
import { useToast } from '@/contexts/Toast/ToastContext';
import useUploadFile from '@/hooks/useUploadFile';
import useCheckJobStatus from '@/hooks/useCheckStatus';
import useLaunchWorkflow from '@/hooks/useLaunchWorkflow';
import useIngestWorkflow from '@/hooks/useIngestWorkflow';

/**
 * UploadPPRZone Component
 * Manages the state and operations for uploading and ingesting a PPR file into Snowflake
 * Uses various hooks for OAuth, file upload, job status checking, and workflow launching.
 */
const UploadPPRZone = () => {
  const url = import.meta.env.VITE_ALTERYX_API_URL;
  const { accessToken } = useOAuth(
    url,
    import.meta.env.VITE_ALTERYX_CLIENT_ID,
    import.meta.env.VITE_ALTERYX_CLIENT_SECRET,
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isStartWorkflowEnabled, setIsStartWorkflowEnabled] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [isIngesting, setIsIngesting] = useState(false);
  const { showToast } = useToast();
  const { uploadFile, fileId } = useUploadFile(url, accessToken||'');
  const { checkJobStatus } = useCheckJobStatus(url, accessToken||'');
  const { launchWorkflow, workflowId } = useLaunchWorkflow(url, accessToken||'');
  const { launchIngestWorkflow, checkIngestJobStatus } = useIngestWorkflow(url, accessToken||'');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setIsStartWorkflowEnabled(true);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    setIsStartWorkflowEnabled(false);

    try {
      const fileId = await uploadFile(selectedFile);
      await launchWorkflow(fileId);
      showToast('Waiting for the file to upload. Please wait before proceeding to step 2.', 'info');
    } catch (error) {
      showToast('Something went wrong. Please try the flow again.', 'error');
    }
  };

  const handleIngest = async () => {
    if(fileId) {
      setIsIngesting(true);
      setIsUploadEnabled(false);

      try {
        const ingestJobId = await launchIngestWorkflow(fileId);
        showToast(`Uploading file ${fileId} to Snowflake. Please wait for the completion message.`, 'info');
        const interval = setInterval(async () => {
          const status = await checkIngestJobStatus(ingestJobId);
          if (status.status === 'Completed' && status.disposition === 'Success') {
            clearInterval(interval);
            showToast('Ingestion to Snowflake completed successfully!', 'success');
            setIsIngesting(false);
            setSelectedFile(null);
            setIsStartWorkflowEnabled(false);
            setIsUploadEnabled(false);
          } else if (status.status === 'Completed' && status.disposition === 'Error') {
            clearInterval(interval);
            showToast('Ingestion to Snowflake was not completed. Please try again!', 'error');
            setIsIngesting(false);
            setSelectedFile(null);
            setIsStartWorkflowEnabled(false);
            setIsUploadEnabled(false);
          }
        }, 12000);
      } catch (error) {
        showToast('Something went wrong. Please try again.', 'error');
        setIsIngesting(false);
      }
    }
  };

  useEffect(() => {
    if (!workflowId)  return;
      const interval = setInterval(async () => {
        const status = await checkJobStatus(workflowId);
        if (status.status === 'Completed' && status.disposition === 'Error') {
          showToast('File verification was not successful. Please try again later!', 'error');
          setIsIngesting(false);
          setSelectedFile(null);
          clearInterval(interval);
        } else if (status.status === 'Completed' && status.disposition !== 'Error') {
          showToast('File verification was successful! Ready to move to step 2.', 'success');
          setIsUploadEnabled(true);
          clearInterval(interval);
        }
      }, 5000);
      return () => clearInterval(interval);

  }, [workflowId]);

  return (
    <Stack gap={2} sx={{
    justifyContent: "space-between",
    alignItems: "stretch",
    padding: "20px"
  }}>
    <div>
      <Typography tag="textsmall">Step 1. Choose the PPR file:</Typography>
      <Box
        onClick={() => {
          document.getElementById('library-images')?.click();
        }}
        style={{
          cursor: 'pointer',
          padding: '20px',
          border: '1px dashed #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
          transition: 'background-color 0.3s',
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = '#e6e6e6')
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = '#f9f9f9')
        }
      >
        <Stack direction={"row"} gap={2} sx={{
          justifyContent: "center",
          alignItems: "stretch",
        }}>
          <Icon icon={"IconFolder"}/>
          <Typography tag="textdefault">
            {selectedFile ? selectedFile.name : 'Choose file'}
          </Typography>
        </Stack>
      </Box>
      <input
        type="file"
        name="library-images"
        id="library-images"
        accept=".xls,.xlsx"
        hidden
        onChange={handleFileChange}
      />
    </div>
    <Typography tag="textsmall">Step 2. Start the validation workflow:</Typography>
    <Button
      type="primary"
      width="100%"
      onClick={handleUpload}
      disabled={!isStartWorkflowEnabled || isIngesting}
    >
      Start Workflow
    </Button>
    <Divider />
    <Typography tag="textsmall">Step 3. Start the upload workflow:</Typography>
    <Button
      type="primary"
      width="100%"
      onClick={handleIngest}
      disabled={!isUploadEnabled || isIngesting}
    >
      Start Upload
    </Button>
  </Stack>);
}

export default UploadPPRZone;