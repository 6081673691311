/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from '@/store';
import {
  ProductTree,
  SiteTree,
  TSimulationMenuOptions,
  WipItemParams,
  WipTree,
  WipTreeElement,
} from '@/store/types';
import { Input, Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { ReactNode, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeExpanded } from '@/store/storeSlice';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { ContextMenu } from '../ContextMenu';
import { sortChildrenByTitleAsc } from '../helpers';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';

export interface ProductTreeRender extends WipItemParams {
  END_PRODUCT_ID: string;
  PARENT_PRODUCT_ID: string;
  PRODUCT: string | ReactNode;
  PRODUCT_CLASS: string | ReactNode;
  PRODUCT_ID: string;
  SITE_ID: string;
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};

type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: keyof WipTree;
};

/**
 * Transforms a product tree item into a product tree render object
 * @param {ProductTree} item - The product tree item to be transformed
 */
const TransformProduct = (item: ProductTree) => {
  const newItem: ProductTreeRender = {
    key: item.PRODUCT_ID.originalValue,
    title: item.PRODUCT.value,
    is_wip: item.is_wip,
    is_deleted: item.is_deleted,
    is_disabled: item.is_disabled,
    is_clicked: item.is_clicked,
    is_selectable: item.is_selectable,
    is_selected: item.is_selected,
    is_hidden: item.is_hidden,
    is_match: item.is_match,
    is_open: item.is_open,
    parent: '',
    children: [],
    END_PRODUCT_ID: item.END_PRODUCT_ID.value,
    PARENT_PRODUCT_ID: item.PARENT_PRODUCT_ID.value,
    PRODUCT: item.PRODUCT.value /* (
      <RenderProduct
        id={item.PRODUCT_ID.value}
        value={item.PRODUCT.value}
        originalValue={item.PRODUCT.originalValue}
      />
    ) */,
    PRODUCT_CLASS: item.PRODUCT_CLASS.value,
    PRODUCT_ID: item.PRODUCT_ID.value,
    SITE_ID: item.SITE_ID.value,
  };

  return newItem;
};

/**
 * Products Component
 * Renders a table of simulations products with various functionalities such as filtering, expanding rows, and context menu actions
 * Manages the state for selected row, anchor element, and filter query
 */
const Products = () => {
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );
  const { setSimulationParams } = useContext(SimulationCtx);

  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);

  const [filterQuery, setFilterQuery] = useState<string>('');

  /**
   * Generates the tree structure for the table
   * Returns an array of product structure objects
   * SITES > PRODUCTS TREE
   * @param {WipTreeElement<SiteTree>} sitesObject - The sites tree object
   * @param {WipTreeElement<ProductTree>} originalObject - The products tree object
   * @returns {TProductStructure[]} - The array of product structure objects
   */
  const generateObjectWithChildren = (
    sitesObject: WipTreeElement<SiteTree>,
    originalObject: WipTreeElement<ProductTree>,
  ): TProductStructure[] => {
    const objectWithChildren: any[] = [];
    const itemMap: { [itemId: string]: TProductStructure } = {};

    sitesObject.allIds.forEach((itemId: string) => {
      const item = sitesObject.byId[itemId];

      const countSites = originalObject.allIds.reduce((count, id) => {
        if (
          originalObject.byId[id].SITE_ID.value ===
            item.SITE_ID.originalValue &&
          originalObject.byId[id].PARENT_PRODUCT_ID.value === null
        ) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);

      const newItem: TProductStructure = {
        id: item.SITE_ID.originalValue,
        area: 'sites',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.SITE_ID.originalValue,
          title: item.SITE.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          children: [],
          END_PRODUCT_ID: '',
          PARENT_PRODUCT_ID: '',
          PRODUCT: item.SITE.value + ' [' + countSites + ']',
          PRODUCT_CLASS: '',
          PRODUCT_ID: '',
          SITE_ID: item.SITE_ID.value,
        },
        children: [],
      };

      objectWithChildren.push(newItem);
      itemMap[itemId] = newItem;
    });

    originalObject.allIds.forEach((itemId: string) => {
      const item = originalObject.byId[itemId];
      const parentId = item.PARENT_PRODUCT_ID.value;
      const siteId = item.SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.PRODUCT_ID.value,
        area: 'products',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: TransformProduct(item),

        children: [],
      };

      // If the parent ID is 'root', add the item directly to the object
      if (parentId === null) {
        objectWithChildren
          .find((obj) => obj.id === siteId)
          .children.push(newItem);
        // itemMap[siteId].children.push(newItem);
      }

      // Store the item in the map for later reference
      itemMap[itemId] = newItem;
    });

    originalObject.allIds.forEach((itemId: string) => {
      const item = originalObject.byId[itemId];
      const parentId = item.PARENT_PRODUCT_ID;
      const siteId = item.SITE_ID.value;
      if (!sitesObject.byId[siteId]) return;

      // If the item has a parent, add it as a child to the respective parent node
      if (parentId !== null && itemMap[parentId.originalValue]) {
        itemMap[parentId.originalValue].children.push(itemMap[itemId]);
      }
    });
    return sortChildrenByTitleAsc(objectWithChildren);
    //return objectWithChildren;
  };

  if (!selectedScenario) return <>No Scenario</>;

  const objectWithChildren = generateObjectWithChildren(
    selectedScenario.data.sites,
    selectedScenario.data.products,
  );

  const TableHeaders: TableHeaderType[] = [
    {
      title: 'Product',
      align: 'left',
      columnKey: 'PRODUCT',
      columnWidth: '600px',
    },
    { title: 'Class', align: 'left', columnKey: 'PRODUCT_CLASS' },
  ];

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(e.target.value);
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;

    console.log('dispatched');
    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };
  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'products') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;

    if (area !== 'products') return;

    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input id="Filter" onChange={handleInput} placeholder="Filter" />
      </div>

      {/*  <pre>{JSON.stringify(objectWithChildren, null, 2)}</pre> */}

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        showDelete={false}
        showRemove={false}
        showDuplicate={false}
      />
      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        typographyTag="textsmall"
        pointerCursor={true}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowClick={(value) => handleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />
    </>
  );
};

export default Products;
