import { useState, useEffect } from 'react';

/**
 * useCheckJobStatus Hook
 * Manages the state and periodic checking of the Snowflake's job status from a given API.
 * Fetches job status using the provided URL and access token, and updates the state accordingly.
 *
 * @param {string} url - The base URL for the API
 * @param {string} accessToken - The access token for authorization
 */
const useCheckJobStatus = (url:string, accessToken:string) => {
  const [jobStatus, setJobStatus] = useState(null);

  const checkJobStatus = async (jobId: string) => {
    try {
      const response = await fetch(`${url}/webapi/v3/jobs/${jobId}?includeMessages=true`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setJobStatus(data);
      return data;
    } catch (error) {
      console.error('Failed to check job status');
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (jobStatus && jobStatus.status !== 'Completed') {
      interval = setInterval(() => {
        checkJobStatus(jobStatus.id);
      }, 50000);
    }
    return () => clearInterval(interval);
  }, [jobStatus]);

  return { checkJobStatus, jobStatus };
};

export default useCheckJobStatus;