import {
  ScenarioSettingsParameters,
  ScenarioSettings as TScenarioSettings,
} from '@/store/types';
import {
  ButtonIcon,
  Checkbox,
  Label,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
  useDebounceCallback,
} from '@data-products-and-ai/react-components';

import { handleInputChange } from '../../helpers';
import styles from './Parameters.module.scss';

const JobsPrioritisation = [
  {
    key: 2,
    value: 'First In First Out',
  },
  {
    key: 3,
    value: 'Slack Coefficient',
  },
  {
    key: 4,
    value: 'Due Date',
  },
  {
    key: 5,
    value: 'Shortest Processing Time',
  },
  {
    key: 6,
    value: 'Longest Processing Time',
  },
] as const;

const FileSelection = [
  {
    key: 1,
    value: 'Excel New',
  }
] as const;

const WorkerPooling = [
  {
    key: 1,
    value: 'Global Pooling',
  },
  {
    key: 2,
    value: 'Resource Pooling',
  },
] as const;

const BatchGroupingOptions = [
  // {
  //   key: 1,
  //   value: 'Batch with Max Capacity',
  // },
  {
    key: 2,
    value: 'Batch with Percentage Fill Grade',
  },
] as const;

const ScrapTrigger = [
  {
    key: 1,
    value: 'As Per Resources',
  },
  {
    key: 2,
    value: 'As Per Process',
  },
] as const;

const WaitBatchTime = [
  {
    key: 1,
    value: 'Wait on batch time as fixed hours',
  },
  {
    key: 2,
    value: 'Wait on batch time as process time percentage',
  }
] as const;
type TParameters = {
  parameters: ScenarioSettingsParameters;
  setForm: React.Dispatch<React.SetStateAction<TScenarioSettings>>;
  parameterIndex: number;
  totalSetParameters: number;
};

/**
 * Parameters Component
 * Renders a form for managing scenario settings parameters with various input fields and controls
 * Manages form state and handles input changes, duplication, and deletion of parameter sets
 * @param {TParameters} props - The properties for the Parameters component
 */
const Parameters = (props: TParameters) => {
  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleInputChange(event, props.setForm, props.parameterIndex);
    },
    1000,
  );

  const handleDuplicate = () => {
    /*   const newParameters = [...prevForm.parameters];  
    newParameters.push({ [id]: newValue }); // Push a new object to the parameters array   */

    props.setForm((prevForm) => ({
      ...prevForm,
      parameters: [...prevForm.parameters, props.parameters],
    }));
  };

  const handleDelete = (indexNumber: number) => {
    props.setForm((prevForm) => ({
      ...prevForm,
      parameters: [
        ...prevForm.parameters.filter((_param, index) => index !== indexNumber),
      ],
    }));
  };
  return (
    <>
      <div className={styles.ParametersContainer}>
        <div
          style={{ backgroundColor: '#FAFAFA', padding: 10, borderRadius: 8 }}
        >
          <Typography tag="textsmall_strong">
            Parameters Set #{props.parameterIndex + 1}
          </Typography>

          <div className={styles.Operations}>
            <Stack direction="row" gap={5}>
              <ButtonIcon
                icon="IconCopy"
                size="extra-small"
                tooltipMessage="Duplicate"
                onClick={() => handleDuplicate()}
              />
              <ButtonIcon
                icon="IconClose"
                size="extra-small"
                tooltipMessage={
                  props.totalSetParameters > 1 ? 'Remove' : undefined
                }
                onClick={() => handleDelete(props.parameterIndex)}
                disabled={props.totalSetParameters <= 1}
              />
            </Stack>
          </div>
        </div>

        <div style={{ padding: 20 }}>
          <Label
            tag="textsmall_strong"
            marginTop={15}
          >
           File Selection
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_FileSelection____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) =>
                handleInputChange(event, props.setForm, props.parameterIndex)
              }
            >
              {FileSelection.map((item) => (
                <Radio
                  key={item.key}
                  label={item.value}
                  name={`DropDown_FileSelection____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={
                    item.key == props.parameters.DropDown_FileSelection
                  }
                />
              ))}
            </RadioGroup>
          </Typography>
            <Label
              tag="textsmall_strong"
              marginTop={15}
              tooltipMessage="To what should the simulation give priority to?"
            >
              Jobs Prioritisation
            </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_JobsPrioritisation____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) =>
                handleInputChange(event, props.setForm, props.parameterIndex)
              }
            >
              {JobsPrioritisation.map((item, index) => (
                <Radio
                  key={index}
                  label={item.value}
                  name={`DropDown_JobsPrioritisation____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={
                    item.key === props.parameters.DropDown_JobsPrioritisation
                  }
                />
              ))}
            </RadioGroup>
          </Typography>

          <Label
            tag="textsmall_strong"
            marginTop={15}
            tooltipMessage="Define if Batch Time should be in hours or percentage"
          >
            Wait on Batch Time
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_WaitOnBatchTime____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) =>
                handleInputChange(event, props.setForm, props.parameterIndex)
              }
            >
              {WaitBatchTime.map((item, index) => (
                <Radio
                  key={index}
                  label={item.value}
                  name={`DropDown_WaitOnBatchTime____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={
                    item.key=== props.parameters.DropDown_WaitOnBatchTime
                  }
                />))}
            </RadioGroup>
          </Typography>

          {props.parameters.DropDown_WaitOnBatchTime===2 ? (
            <>
              <Label tag="textsmall_strong" marginTop={15}>
                Wait on Batch Time Percentage
              </Label>

              <Slider
                id={`Variable_WaitOnBatchTimeAsProcessTimePercentage____${props.parameterIndex}`}
                min={0}
                max={100}
                valueSufix="%"
                typographyColor="#666666"
                showValue
                defaultValue={
                  props.parameters
                    .Variable_WaitOnBatchTimeAsProcessTimePercentage
                }
                onChange={(event) => handleInputChangeDebounced(event)}
              />
            </>
          ) : (
            <>
              <Label tag="textsmall_strong" marginTop={15}>
                Number of hours
              </Label>

              <Slider
                id={`Fixed_WaitOnBatchTimeAsFixedHours____${props.parameterIndex}`}
                min={1}
                max={96}
                valueSufix=" hours"
                typographyColor="#666666"
                showValue
                defaultValue={
                  props.parameters.Fixed_WaitOnBatchTimeAsFixedHours
                }
                onChange={(event) => handleInputChangeDebounced(event)}
              />
            </>
          )}
          <Label tag="textsmall_strong" marginTop={15}>
            Worker Pooling
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_Worker_Pooling____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) => {
                handleInputChange(event, props.setForm, props.parameterIndex);
              }}
            >
              {WorkerPooling.map((item, index) => (
                <Radio
                  key={index}
                  label={item.value}
                  name={`DropDown_Worker_Pooling____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={
                    item.key=== props.parameters.DropDown_Worker_Pooling
                  }
                />
              ))}
            </RadioGroup>
          </Typography>
          <Label tag="textsmall_strong" marginTop={15}>
            Batch Grouping Option
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`Batch_Grouping_Option____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) => {
                handleInputChange(event, props.setForm, props.parameterIndex);
              }}
            >
              {BatchGroupingOptions.map((item) => (
                <Radio
                  key={item.key}
                  label={item.value}
                  name={`Batch_Grouping_Option____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={item.key === props.parameters.Batch_Grouping_Option}
                />
              ))}
            </RadioGroup>
          </Typography>

          {props.parameters.Batch_Grouping_Option === 2 && (
            <>
              <Label tag="textsmall_strong" marginTop={15}>
                Batch Fill Grade Percentage
              </Label>
              <Slider
                id={`Batch_Fill_Grade_Percentage____${props.parameterIndex}`}
                min={0}
                max={1}
                step={0.01}
                valueSufix=""
                typographyColor="#666666"
                showValue
                defaultValue={props.parameters.Batch_Fill_Grade_Percentage}
                onChange={(event) => handleInputChangeDebounced(event)}
              />
            </>
          )}
          <Label
            tag="textsmall_strong"
            marginTop={15}
          >
            Scrap Trigger
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_Scrap_Trigger____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) =>
                handleInputChange(event, props.setForm, props.parameterIndex)
              }
            >
              {ScrapTrigger.map((item) => (
                <Radio
                  key={item.key}
                  label={item.value}
                  name={`DropDown_Scrap_Trigger____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={
                    item.key == props.parameters.DropDown_Scrap_Trigger
                  }
                />
              ))}
            </RadioGroup>
          </Typography>
          <Label tag="textsmall_strong" marginTop={15}>
            Other Parameters
          </Label>
          <Typography tag="textsmall">
            <Stack direction="row" gap={20}>
              <Checkbox
                id={`DropDown_UseEachPOStartDate____${props.parameterIndex}`}
                label="Use each PO Start Date"
                value="true"
                isChecked={props.parameters.DropDown_UseEachPOStartDate}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
              <Checkbox
                id={`DropDown_UseOfAdditionalEquipment____${props.parameterIndex}`}
                label="Additional Equipment"
                value="true"
                isChecked={props.parameters.DropDown_UseOfAdditionalEquipment}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
              <Checkbox
                id={`DropDown_Use_Storage____${props.parameterIndex}`}
                label="Use Storage"
                value="false"
                isChecked={props.parameters.DropDown_Use_Storage}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
              <Checkbox
                id={`DropDown_Use_Personnel_Module____${props.parameterIndex}`}
                label="Use Personnel Module"
                value="false"
                isChecked={props.parameters.DropDown_Use_Personnel_Module}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
              <Checkbox
                id={`DropDown_Scrap_Delete____${props.parameterIndex}`}
                label="Scrap Delete"
                value="true"
                isChecked={props.parameters.DropDown_Scrap_Delete}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
            </Stack>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Parameters;
