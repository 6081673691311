//external dependencies
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';

//internal dependencies
import {
  Stack,
  Typography,
  useTranslation,
} from '@data-products-and-ai/react-components';
import styles from './AzureLoginButton.module.scss';
import AzureLogo from './assets/azure_white.png';

/**
 * handleLogin Function
 * Initiates the login process using MSAL's loginRedirect method
 * Redirects the user to the Azure login page
 *
 * @param {IPublicClientApplication} msalInstance - The MSAL instance used for authentication
 */
const handleLogin = (msalInstance: IPublicClientApplication) => async () => {
  await msalInstance.loginRedirect();
};

/**
 * LoginButton Component
 * Renders a button that initiates the Azure login process using MSAL
 * Displays an error message if login fails
 * Utilizes various hooks and context for state management and translation
 *
 * @returns {JSX.Element} - The rendered component
 */
const LoginButton = () => {
  const [errorMessage] = useState(null);
  const { instance } = useMsal();
  const translate = useTranslation();
  return (
    <>
      <button className={styles.button} onClick={handleLogin(instance)}>
        <Stack direction="row" horizontalAlign="center">
          <img src={AzureLogo} height={20} style={{ marginRight: 10 }} alt="" />
          <Typography tag="textdefault">{translate('LOGIN.BUTTON')}</Typography>
        </Stack>
      </button>

      {errorMessage && (
        <div className="error-message" style={{ color: 'red', marginTop: 20 }}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default LoginButton;
