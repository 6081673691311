import { useState } from 'react';

/**
 * useIngestWorkflow Hook
 * Manages the state and operations for launching and checking the status of the Snowflake ingest workflow.
 * Uses the provided URL and access token for API requests.
 * @param {string} url - The base URL for the API
 * @param {string} accessToken - The access token for authorization
*/
const useIngestWorkflow = (url: string, accessToken:string) => {
  const [ingestJobId, setIngestJobId] = useState(null);
  const [ingestJobStatus, setIngestJobStatus] = useState(null);

  const launchIngestWorkflow = async (fileId: File) => {
    try {
      const envWorfkflowId = import.meta.env.VITE_ALTERYX_WORKFLOW_ID;
      const env = import.meta.env.VITE_ENVIRONMENT;
      const response = await fetch(`${url}/webapi/user/v2/workflows/${envWorfkflowId}/jobs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          credentialId: '637a2a09360a37bf2e8198ff',
          questions: [{ name: env==='DEV'?'File Browse (183)':'File Browse (1114)', value: fileId }],
          priority: 'High',
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIngestJobId(data.id);
      return data.id;
    } catch (error) {
      console.error('Failed to launch ingest workflow', error);
    }
  };

  const checkIngestJobStatus = async (jobId:string) => {
    try {
      const response = await fetch(`${url}/webapi/v1/jobs/${jobId}?includeMessages=true`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIngestJobStatus(data);
      return data;
    } catch (error) {
      console.error('Failed to check ingest job status');
    }
  };

  return { launchIngestWorkflow, checkIngestJobStatus, ingestJobId, ingestJobStatus };
};

export default useIngestWorkflow;