/**
 * statusMapping Object
 * Maps single-letter status codes to their full string descriptions
 */
const statusMapping: { [key: string]: string } = {
  M: 'Moving',
  S: 'Setup',
  F: 'Failure',
  N: 'New Resource',
  D: 'Defect Resource',
};

/**
 * Maps a single-letter status code to its full string description
 * Returns 'Unknown Status' if the status code is not found in the mapping
 *
 * @param {string} status - The single-letter status code
 * @returns {string} - The full string description of the status
 */
export const mapStatusToFullString = (status: string): string => {
  const firstLetter = status.charAt(0);
  return statusMapping[firstLetter] || 'Unknown Status';
};
