import { configureStore } from '@reduxjs/toolkit';
import storeReducer from './storeSlice';

/**
 * Redux Store Configuration
 * Configures the Redux store with a root reducer and custom middleware settings
 * Disables immutable and serializable state invariant middleware checks
 *
 * @returns {Store} - The configured Redux store
 */
export const store = configureStore({
  reducer: {
    store: storeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
