import { apiConfig } from '@/config/tokenConfig';
import { useMsal } from '@azure/msal-react';
import { JwtPayload, jwtDecode } from 'jwt-decode';

/**
 * useAccessToken Hook
 * Provides a function to get a silent access token using MSAL
 * Decodes the token to extract groups and stores them in local storage
 * @returns {Object} - An object containing the getSilentToken function
 */
const useAccessToken = () => {
  const { instance, accounts } = useMsal();

  const tokenRequest = {
    account: accounts[0],
    scopes: apiConfig.resourceScopes,
  };

  interface IToken extends JwtPayload {
    groups: string[];
  }

  /**
   * Gets a silent access token using MSAL
   * Decodes the token to extract groups and stores them in local storage
   */
  const getSilentToken = async () => {
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
    const tokenDecoded: IToken = jwtDecode(tokenResponse.accessToken);
    localStorage.setItem('groups', JSON.stringify(tokenDecoded.groups));

    return tokenResponse.accessToken;
  };

  return { getSilentToken };
};

export default useAccessToken;
