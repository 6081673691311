import {
  Select,
  SelectOption,
  Stack,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';

type TShiftsTimePicker = {
  id: string;
  seconds: number;
  onChange: (value: number) => void;
};

/**
 * secondsToHoursMinutes Function
 * Converts a time value in seconds to an object with hours and minutes
 *
 * @param {number} seconds - The time value in seconds
 * @returns {object} - An object containing hours and minutes
 */
function secondsToHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  return { hours, minutes };
}

/**
 * generateOptions Function
 * Generates an array of SelectOption components for a given range of numbers
 *
 * @param {number} min - The minimum value for the options
 * @param {number} max - The maximum value for the options
 * @returns {JSX.Element[]} - An array of SelectOption components
 */

const generateOptions = (min: number, max: number) => {
  const options = [];
  for (let i = min; i <= max; i++) {
    const time = i.toString().padStart(2, '0'); // Ensure leading zero for single-digit hours
    options.push(<SelectOption key={time} label={time} value={i.toString()} />);
  }
  return options;
};

/**
 * FormShiftsTimePicker Component
 * Renders a time picker with dropdowns for hours and minutes
 * Manages the state for each time unit and calls the onChange callback with the combined value in seconds
 *
 * @param {string} id - The unique identifier for the time picker
 * @param {number} seconds - The initial value in seconds
 * @param {function} onChange - The callback function to be called with the combined time value in seconds
 * @returns {JSX.Element} - The rendered component
 */
const FormShiftsTimePicker = ({
  id,
  seconds = 0,
  onChange,
}: TShiftsTimePicker) => {
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const { hours, minutes } = secondsToHoursMinutes(seconds);
    setHour(hours);
    setMinutes(minutes);
  }, [seconds]);

  const handleInputChange = (inputType: string, value: number) => {
    if (!value) value = 0;

    if (inputType === 'hours') {
      setHour(value);
    } else if (inputType === 'minutes') {
      setMinutes(value);
    }
    return;
  };

  useEffect(() => {
    const combinedValue = hour * 3600 + minutes * 60;

    if (onChange) {
      onChange(combinedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, hour]);

  return (
    <Stack direction="row" gap={10}>
      <Select
        onChange={(event) =>
          handleInputChange('hours', parseInt(event.target.value))
        }
        size="small"
        defaultValue={hour.toString()}
        id={`${id}_HOUR`}
      >
        {generateOptions(0, 23)}
      </Select>
      <div>:</div>
      <Select
        onChange={(event) =>
          handleInputChange('minutes', parseInt(event.target.value))
        }
        size="small"
        defaultValue={minutes.toString()}
        id={`${id}_MINUTES`}
      >
        {generateOptions(0, 59)}
      </Select>
    </Stack>
  );
};

export default FormShiftsTimePicker;
