import { useMsal } from '@azure/msal-react';

/**
 * useUserDetails Hook
 * Retrieves user details from the MSAL accounts.
 */
const useUserDetails = () => {
  const { accounts } = useMsal();
  const username = accounts[0]?.username;
  const name = accounts[0]?.name;
  const gid = accounts[0]?.idTokenClaims?.gid as string | undefined;

  return { name, username, gid };
};
export default useUserDetails;
