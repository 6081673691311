import { WIP_CATEGORIES } from '@/constants/categories';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import useAccessToken from '@/hooks/useAccessToken';
import { ApiConnector } from '@/services/ApiConnector';
import { AppDispatch } from '@/store';
import { initialWip, insertWIP, insertWIPTree } from '@/store/storeSlice';
import { Wip } from '@/store/types';
import { generateUID } from '@/utils/helpers';
import { Typography } from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addTreeChildren, generateTree } from './helpers/createTree';
import { transformData } from './helpers/transformData';
import { FetchApiType } from './types';

/**
 * LoadWip Component
 * Manages the loading and transformation of the initial scenario for the factories selected
 * Handles the insertion of WIP data and tree structures into the Redux store
 */
const LoadWip = () => {
  const url = import.meta.env.VITE_API_URL;
  const UUID = generateUID();

  const WIPObject: Wip = { ...initialWip };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WIPTree: any = {
    shifts: {},
    shift_calendar: {},
  };

  /**
   * FetchAPI Function
   * Fetches data from the API and handles pagination and transformation
   * Updates the WIP object with the fetched data and manages loading states
   *
   * @param {FetchApiType} params - The parameters for the API call
   */
  const FetchAPI = async ({
    token,
    endpoint,
    key,
    success,
    fail,
    step = 0,
    responseObj = {
      key: key,
      numRows: 0,
      numPartitions: 0,
      data: [],
      partitions: [],
      rowType: [],
      statementHandle: '',
    },
  }: FetchApiType) => {
    try {
      const response = await ApiConnector(
        'GET',
        endpoint,
        { baseUrl: url, accessToken: token },
        { 'Content-Type': 'application/json', Accept: 'application/json' },
      );

      response.data.body.data.map((nestedArray: string[]) => {
        if (responseObj) return responseObj.data.push(nestedArray);
        return;
      });

      if (step === 0) {
        responseObj.partitions =
          response.data.body.resultSetMetaData.partitionInfo;

        responseObj.rowType = response.data.body.resultSetMetaData.rowType;

        responseObj.numRows = response.data.body.resultSetMetaData.numRows;
        responseObj.statementHandle = response.data.body.statementHandle;
        responseObj.numPartitions = responseObj.partitions.length;
      }
      if (step < responseObj.numPartitions - 1) {
        ++step;
        await FetchAPI({
          token,
          endpoint: `handles/${responseObj.statementHandle}?partition=${step}`,
          success,
          fail,
          key,
          step,
          responseObj,
        });
        return;
      }

      WIPObject[key] = transformData(UUID, responseObj);
      console.log(key, WIPObject[key]);

      success();
      return responseObj;
    } catch (error) {
      fail();
      console.error(key, 'Error fetching data:', error);
      // Update the state with an empty array which means there was an error - null means it's loading
      /*   cb([]); */

      throw error;
    }
  };

  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const { getSilentToken } = useAccessToken();
  const dispatch: AppDispatch = useDispatch();

  const insert = () => {
    dispatch(
      insertWIP({
        wip: WIPObject,
      }),
    );
    dispatch(
      insertWIPTree({
        wipTree: WIPTree,
      }),
    );
  };

  type WipKeys = keyof Wip;
  type WipBooleanType = {
    [K in WipKeys]: true | false | null;
  };
  //const { username, gid } = useUserDetails();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [fetchWip, setFetchWip] = useState<boolean>(false);

  const [, setIsLoaded] = useState<WipBooleanType>({
    additional_equipments: null,
    operations: null,
    production_orders: null,
    products: null,
    set_additional_equipments: null,
    set_work_units: null,
    shift_calendar: null,
    shifts: null,
    shutdowns: null,
    work_plans: null,
    sites: null,
    workplan_processes: null,
    workunit_classes: null,
    additionalequipment_classes: null,
    process_types: null,
    operation_types: null,
    resources: null,
    resource_lifecycle: null,
    resource_lifecycle_status: null,
    resource_names: null,
    resource_types: null,
    resource_classes: null,
    resource_sub_classes: null,
    resource_grades: null,
    operations_resources: null,
    pause_shift: null,
    enterprises: null,
    product_classes: null,
    resource_buildings: null,
    skills: null,
    personnel: null,
    personnel_group: null,
    personnel_skills: null,
    operations_skills: null,
    personnel_by_groups: null,
    pauses: null,
    distinct_personnel_group: null,
  });

  // Get Token
  useEffect(() => {
    if (simulationParams.factoryDataLoaded) return;
    getSilentToken().then((token) => {
      setAuthToken(token);
    });
    if (simulationParams.factoryDataLoaded === null) setFetchWip(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationParams.factoryDataLoaded]);

  useEffect(() => {
    if (!authToken) return;
    if (!fetchWip) return;

    /**
     * fetchAll Function
     * Fetches all required WIP data and processes it
     * Calls FetchAPI for each WIP category and handles the results
     * Generates tree structures and inserts them into the Redux store
     */
    const fetchAll = async () => {
      await getSilentToken();

      const fetchPromises = WIP_CATEGORIES.map((obj) =>
        FetchAPI({
          token: authToken,
          endpoint:
            obj.endpoint +
            (obj.hasSiteFilter
              ? `?` +
                simulationParams.selectedSites
                  .map((number) => `SITE_ID=${number}`)
                  .join('&')
              : ''),
          key: obj.key,
          success: () =>
            setIsLoaded((prevState) => ({ ...prevState, [obj.key]: true })),
          fail: () =>
            setIsLoaded((prevState) => ({ ...prevState, [obj.key]: false })),
        }),
      );

      const result = await Promise.allSettled(fetchPromises);

      for (const promise of result) {
        if (promise.status !== 'fulfilled') {
          setSimulationParams((prevState) => ({
            ...prevState,
            factoryDataLoaded: false,
          }));
          setFetchWip(false);
          return;
          break;
        }
      }

      WIP_CATEGORIES.forEach((obj) => {
        WIPTree[obj.key] = generateTree[obj.key](WIPObject[obj.key]);
        console.log(obj.key, WIPTree[obj.key]);
      });

      addTreeChildren.shifts(WIPTree.shifts, WIPTree.shift_calendar);
      addTreeChildren.operations(WIPTree.operations, WIPTree.work_plans);

      console.log('FULL TREE', WIPTree);

      insert();
      setSimulationParams((prevState) => ({
        ...prevState,
        factoryDataLoaded: true,
      }));
    };

    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWip]);

  return (
    <>
        <Typography tag="textextrasmall">
          {simulationParams.factoryDataLoaded === null ? (
            <>Loading data from factory...</>
          ) : simulationParams.factoryDataLoaded ? (
            <>Data loaded successfully</>
          ) : (
            <>Error Loading Data</>
          )}
        </Typography>
    </>
  );
};

export default LoadWip;
