/**
 * useGroups Hook
 * Retrieves the 'groups' item from local storage.
 *
 * @returns {string | null} - The value of the 'groups' item from local storage, or null if it doesn't exist
 */
const useGroups = () => {
  const groups = localStorage.getItem('groups');

  return groups;
};

export default useGroups;
